import { Button, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { Business_Profile_Enum, Privacy_Setting_Enum } from 'kheops-graphql';
import ContactInformationTemplate from '../contactInformation/ContactInformationTemplate';
import { SettingsDialogViewProps } from './SettingsDialog';
import { useUpdateUserContactInformationMutation } from '../../mutations/__generated__/updateUserContactInformation.generated';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import { UserRoleDocument } from '../../queries/__generated__/userRole.generated';
import EmailContactInformationInput from '../contactInformation/EmailContactInformationInput';
import PhoneContactInformationInput from '../contactInformation/PhoneContactInformationInput';
import { currentContextAtom, userAtom } from '../../state';

interface ContactInformationSettingsProps extends SettingsDialogViewProps {
  cancelLabel: string;
  submitLabel: string;
}

interface ContactInformationForm {
  value?: string;
  privacy: Privacy_Setting_Enum;
  ignore: boolean;
}

export interface ContactInformations {
  mobile_phone_number: ContactInformationForm,
  landline_number: ContactInformationForm,
  email: ContactInformationForm
}

export default function ContactInformationSettings({ next, submitLabel, cancelLabel }: ContactInformationSettingsProps): React.JSX.Element {
  const { t } = useTranslation(['settings', 'common']);
  const [updateContactInformation, { data, loading }] = useUpdateUserContactInformationMutation({
    refetchQueries: [UserRoleDocument],
  });
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const user = useAtomValue(userAtom);
  const { realm } = useAtomValue(currentContextAtom);

  const form = useForm<ContactInformations>({
    defaultValues: {
      mobile_phone_number: {
        value: user.contactInformation?.mobilePhone || '',
        privacy: user.contactInformation?.mobilePrivacySetting || (realm === Business_Profile_Enum.Supplier ? Privacy_Setting_Enum.Everyone : Privacy_Setting_Enum.Contractualized),
        ignore: !!user.contactInformation?.emailPrivacySetting && !user.contactInformation?.mobilePhone,
      },
      landline_number: {
        value: user.contactInformation?.landlinePhone || '',
        privacy: user.contactInformation?.landlinePrivacySetting || Privacy_Setting_Enum.Everyone,
        ignore: !!user.contactInformation?.emailPrivacySetting && !user.contactInformation?.landlinePhone,
      },
      email: {
        privacy: user.contactInformation?.emailPrivacySetting || Privacy_Setting_Enum.Everyone,
      },
    },
    mode: 'onChange',
  });

  const {
    formState: {
      isValid,
      isDirty,
    },
  } = form;

  const handleSubmit = useCallback(() => {
    const {
      mobile_phone_number: {
        value: mobile_phone,
        privacy: mobile_privacy_setting,
        ignore: ignore_mobile_phone,
      },
      landline_number: {
        value: landline_phone,
        privacy: landline_privacy_setting,
        ignore: ignore_landline_phone,
      },
      email: {
        privacy: email_privacy_setting,
      },
    } = form.getValues();

    updateContactInformation({
      variables: {
        mobile_phone: !ignore_mobile_phone ? mobile_phone : null,
        mobile_privacy_setting,
        landline_phone: !ignore_landline_phone ? landline_phone : null,
        landline_privacy_setting,
        email_privacy_setting,
        userId: user.id,
      },
    });
  }, [form, user]);

  useEffect(() => {
    if (data?.update_user_by_pk?.id) {
      setCommonSnackbarProps({
        label: t('settings:your_profile_has_been_updated'),
        snackbarProps: {
          open: true,
        },
      });

      next();
    }
  }, [data]);

  return (
    <FormProvider {...form}>
      <DialogTitle>
        <Typography variant="displaySmall" sx={{ mb: 2 }}>
          {t('settings:your_contact_information')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography variant="bodyMedium" color="secondary" sx={{ mb: 1, textAlign: 'center' }}>
          {t('settings:your_contact_information_description')}
        </Typography>
        <ContactInformationTemplate
          mobilePhoneNumberComponent={<PhoneContactInformationInput fieldName="mobile_phone_number" />}
          landlineNumberComponent={<PhoneContactInformationInput fieldName="landline_number" />}
          emailComponent={<EmailContactInformationInput />}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => next(true)}
          color="primary"
          variant="outlined"
        >
          {cancelLabel}
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          color="primary"
          variant="contained"
          disabled={!isValid || !isDirty}
        >
          {submitLabel}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
