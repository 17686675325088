import { Box, Grid2, useTheme } from '@mui/material';
import { InstantSearch, Configure } from 'react-instantsearch';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { IndexUiState } from 'instantsearch.js';
import { useAtomValue } from 'jotai';
import { Contract_Status_Enum } from 'kheops-graphql';
import { useSearchClient } from '../../hooks/useSearchClient';
import { PageWrapper } from '../../common/components/PageWrapper';
import SearchProductHits from './SearchProductHits';
import Basket from '../../basket/Basket';
import { HistoryIndexUiState, OnStateChangeArgs, singleOrderPageDisplayModeAtom } from '../state/state';
import TrumpeterButterflyImage from '../../assets/images/trumpeter_butterfly.png';
import { RoutePaths } from '../../routes/AppRoutes';
import SearchPagination from '../SearchPagination';
import { currentContextAtom } from '../../state';
import OrderRecommendations from '../recommendations/OrderRecommendations';
import SearchProductPageToolsHeader from './SearchProductPageToolsHeader';

export default function SearchProductPage(): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(['search', 'products', 'contracts']);
  const { companyId } = useAtomValue(currentContextAtom);
  const searchClient = useSearchClient();
  const buyerFilter = useMemo(() => {
    return `buyers:${companyId}_${Contract_Status_Enum.Signed}`;
  }, [companyId]);
  const displayMode = useAtomValue(singleOrderPageDisplayModeAtom);
  const [fullTextSearchValue, setFullTextSearchValue] = useState(window.history.state.indexUiState?.query || '');

  const initialIndex = import.meta.env.REACT_APP_ALGOLIA_PACKAGING_INDEX;
  const sortIndex = import.meta.env.REACT_APP_ALGOLIA_PACKAGING_SORT_ALPHA_INDEX;
  const [searchIndex, setSearchIndex] = useState(window.history.state.indexUiState?.refinementList?.company?.length === 1 ? sortIndex : initialIndex);
  const [hasActiveFilter, setHasActiveFilter] = useState(false);

  const checkActiveFilters = (uiState?: IndexUiState): boolean => {
    return !!(uiState && (Object.keys(uiState.refinementList || {}).some((key) => uiState.refinementList?.[key]?.length) || uiState.query));
  };

  const handleStateChange = ({ uiState, setUiState }: OnStateChangeArgs): void => {
    /*
      currentIndex is used to make sure we access the current uiState.
      We cannot use searchindex directly because within this function its offset with current used index when we refine with companies for example
    */
    const currentIndex = Object.keys(uiState)[0];
    const numberOfFilteredCompanies = uiState[currentIndex].refinementList?.company?.length || 0;
    setFullTextSearchValue(uiState[currentIndex].query || '');

    window.history.replaceState({
      ...window.history.state,
      indexUiState: uiState[currentIndex],
    }, '');

    setSearchIndex(numberOfFilteredCompanies === 1 ? sortIndex : initialIndex);
    setUiState(uiState);

    setHasActiveFilter(checkActiveFilters(uiState[currentIndex]));
  };

  useEffect(() => {
    setHasActiveFilter(checkActiveFilters(window.history.state.indexUiState));
  }, []);

  return (
    <PageWrapper
      sx={{
        backgroundColor: 'background.paper',
        flex: 1,
        pt: 0,
        [theme.breakpoints.down('sm')]: {
          px: 0,
        },
      }}
    >
      <InstantSearch
        indexName={searchIndex}
        searchClient={searchClient}
        initialUiState={{
          [searchIndex]: window.history.state.indexUiState as HistoryIndexUiState,
        }}
        onStateChange={handleStateChange}
      >
        <Configure
          hitsPerPage={24}
          filters={buyerFilter}
        />
        <Grid2 container columnSpacing={2}>
          <Grid2 size={{ xs: 12, md: 8, lg: 9 }} rowSpacing={2}>
            <SearchProductPageToolsHeader
              setFullTextSearchValue={setFullTextSearchValue}
              fullTextSearchValue={fullTextSearchValue}
            />

            {/* display used instead of tsx condition to avoid spamming queries done in OrderRecommendations component */}
            <Box sx={{ display: hasActiveFilter ? 'none' : 'block' }}>
              <OrderRecommendations />
            </Box>
            <Box
              sx={{
                [theme.breakpoints.down('sm')]: {
                  px: 1,
                },
                mb: 2,
              }}
            >
              <SearchProductHits
                displayMode={displayMode}
                emptyPlaceholderProps={{
                  title: t('products:no_product'),
                  description: t('search:no_product_description'),
                  imageSrc: TrumpeterButterflyImage,
                  buttonLabel: t('contracts:discover_new_suppliers'),
                  linkDestination: generatePath(RoutePaths.DISCOVERY_SUPPLIERS),
                }}
              />
              <SearchPagination sx={{ width: 'fit-content', mt: 2 }} />
            </Box>
          </Grid2>
          <Grid2
            size={{ xs: 4, lg: 3 }}
            sx={{
              alignSelf: 'start',
              position: 'sticky',
              top: '88px',
              height: {
                md: 'calc(100vh - 176px)',
                lg: 'calc(100vh - 104px)',
              },
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            }}
          >
            <Basket />
          </Grid2>
        </Grid2>
      </InstantSearch>
    </PageWrapper>
  );
}
