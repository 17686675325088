import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { Business_Profile_Enum, Staff_Role_Enum, User_Product_Family } from 'kheops-graphql';
import { currentBuyingCompanyIdAtom } from '../basket/state/state';
import { AUTH_TOKEN, AuthToken } from '../common/models/auth.model';
import { CompanyAddress, ContactInformation, Context, UserDeliveryZone } from '../common/models/user.models';
import { UserRoleQuery, useUserRoleQuery } from '../queries/__generated__/userRole.generated';
import { RoutePaths } from '../routes/AppRoutes';
import useAuth from './useAuth/useAuth';
import { availableContextsAtom, currentContextAtom, userAtom } from '../state';

type CompanyContext = Required<UserRoleQuery>['company'][number];
type ProductFamily = Pick<User_Product_Family, 'global_setting' | 'product_family' | 'product_sub_family'>;

export default function useCurrentUser(): void {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryContextId = searchParams.get('contextId');
  const localStorageContextId = localStorage.getItem('contextId');

  const { isAuthenticated } = useAuth();
  const setUser = useSetAtom(userAtom);
  const setCurrentContext = useSetAtom(currentContextAtom);
  const setAvailableContexts = useSetAtom(availableContextsAtom);
  const setCurrentBuyingCompanyId = useSetAtom(currentBuyingCompanyIdAtom);

  const transformUserRoleIntoContext = useCallback((company: CompanyContext, roleAssignmentId: string, productFamilies?: ProductFamily[], deliveryZones?: UserDeliveryZone[]): Context => {
    const { tradeName, legalName, addresses, brand, id, business_profile, photos, contactEmail, contactPhoneNumber, description, delivery_description, delivery_instructions, delivery_email, delivery_phone_number, delivery_hours, delivery_address, invoicing_instructions, invoicing_email, invoicing_phone_number, invoicing_address, supplier_commission_rate } = company!;
    let sortedDeliveryZones: UserDeliveryZone[] | undefined;

    if (deliveryZones) {
      const allAreasIndex = deliveryZones.findIndex(
        (zone) => zone.administrative_areas!.some((administrativeArea) => administrativeArea.area === 'ALL_AREAS'),
      );

      if (allAreasIndex !== -1) {
        sortedDeliveryZones = [...deliveryZones];
        sortedDeliveryZones.splice(allAreasIndex, 1);
        sortedDeliveryZones.push(deliveryZones[allAreasIndex]);
      }
    }

    return {
      realm: business_profile,
      roleAssignmentId,
      companyId: id,
      companyName: tradeName,
      companyLegalName: legalName,
      companyBrand: brand,
      companyAddress: {
        administrativeAreaLevel2: company.addresses[0].administrativeAreaLevel2,
        formattedAddress: company.addresses[0].formattedAddress,
        latitude: company.addresses[0].latitude,
        longitude: company.addresses[0].longitude,
        locality: company.addresses[0].locality,
        postalCode: company.addresses[0].postalCode,
        countryCode: company.addresses[0].countryCode,
      },
      companyRoleAssignments: company.role_assignments,
      companyGeoloc: {
        lat: addresses[0].latitude,
        lng: addresses[0].longitude,
      },
      companyPhotos: photos,
      productFamilies,
      deliveryZones: sortedDeliveryZones || deliveryZones,
      contactEmail,
      contactPhoneNumber,
      description,
      deliveryDescription: delivery_description,
      deliveryInstructions: delivery_instructions,
      deliveryPhoneNumber: delivery_phone_number,
      deliveryEmail: delivery_email,
      deliveryHours: delivery_hours,
      deliveryAddress: delivery_address,
      invoicingInstructions: invoicing_instructions,
      invoicingPhoneNumber: invoicing_phone_number,
      invoicingEmail: invoicing_email,
      invoicingAddress: invoicing_address,
      commissionRate: supplier_commission_rate as number | undefined,
    };
  }, []);

  const getAvailableContexts = useCallback((data: UserRoleQuery): Context[] => {
    if (!data.user_by_pk) {
      return [{
        realm: Business_Profile_Enum.Buyer,
        companyId: '',
        companyName: '',
        companyLegalName: '',
        roleAssignmentId: '',
        companyRoleAssignments: [],
        companyAddress: {} as CompanyAddress,
      }];
    }

    const contextList = data.user_by_pk.role_assignments.map((roleData) => {
      return transformUserRoleIntoContext(roleData.company!, roleData.id, roleData.product_families, roleData.company!.delivery_zones);
    });
    const userIsAdmin = data.user_by_pk.staff_user?.role === Staff_Role_Enum.Admin;

    if (data.company?.[0] && userIsAdmin && !contextList.some((company) => company.companyId === data.company![0].id)) {
      contextList.push(transformUserRoleIntoContext(data.company[0], '', undefined, data.company[0].delivery_zones));
    }

    return contextList;
  }, []);

  const verifyContextId = useCallback((contextId: string, userPossibleContext: Context[]): Context | undefined => {
    return userPossibleContext.find((context) => (context.companyId === contextId));
  }, []);

  const getContext = useCallback((userPossibleContext: Context[]): Context => {
    if (queryContextId && !verifyContextId(queryContextId, userPossibleContext)) {
      navigate(RoutePaths.ROOT);
    }

    const context = verifyContextId(queryContextId!, userPossibleContext) || verifyContextId(localStorageContextId!, userPossibleContext) || userPossibleContext[0];
    localStorage.setItem('contextId', context.companyId!);

    return context;
  }, [queryContextId, localStorageContextId]);

  const { userId, sendbirdAccessToken } = useMemo(() => {
    if (!isAuthenticated) {
      return {};
    }
    const token = localStorage.getItem(AUTH_TOKEN);
    if (!token) {
      return {};
    }
    const accessToken: AuthToken = JSON.parse(token);
    const jwtContent = jwtDecode<{ sub: string }>(accessToken.access_token);

    return {
      userId: jwtContent.sub,
      sendbirdAccessToken: accessToken.sendbird_access_token,
    };
  }, [isAuthenticated]);

  const companyId = queryContextId || localStorageContextId;
  const { data } = useUserRoleQuery({
    variables: { id: userId!, companyId, hasCompanyId: !!companyId },
    skip: !userId,
  });

  useEffect((): void => {
    if (!data?.user_by_pk) {
      return;
    }

    const {
      id,
      email,
      first_name,
      last_name,
      mobile_phone,
      mobile_privacy_setting,
      landline_phone,
      landline_privacy_setting,
      email_privacy_setting,
      default_avatar,
      auth_provider_avatar_url,
      staff_user,
    } = data.user_by_pk;
    const userAvailableContexts = getAvailableContexts(data);

    // sync user companies with segment.
    userAvailableContexts.forEach((context) => {
      window.analytics.group(context.companyId);
    });

    const verifiedContext = getContext(userAvailableContexts);
    const contactInformation: ContactInformation | undefined = email_privacy_setting
      ? {
        mobilePhone: mobile_phone,
        mobilePrivacySetting: mobile_privacy_setting,
        landlinePhone: landline_phone,
        landlinePrivacySetting: landline_privacy_setting,
        emailPrivacySetting: email_privacy_setting,
      }
      : undefined;

    if (verifiedContext.realm === Business_Profile_Enum.Buyer) {
      setCurrentBuyingCompanyId(verifiedContext.companyId);
    }

    setUser({
      id,
      contactInformation,
      sendbirdAccessToken: sendbirdAccessToken!,
      email: email!,
      first_name,
      last_name,
      avatars: {
        auth_provider_avatar_url,
        default_avatar,
      },
      isStaff: staff_user?.role === Staff_Role_Enum.Admin,
    });
    setCurrentContext(verifiedContext);
    setAvailableContexts(userAvailableContexts);
  }, [data?.user_by_pk, data?.company?.[0]]);
}
