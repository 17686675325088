import TuneIcon from '@mui/icons-material/Tune';
import { Box, Button, Divider, Grid2, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InstantSearch } from 'react-instantsearch';
import { Provider, useAtomValue, useSetAtom } from 'jotai';
import { PageWrapper } from '../../common/components/PageWrapper';
import { useSearchClient } from '../../hooks/useSearchClient';
import SearchBuyerHits from './SearchBuyerHits';
import SearchPagination from '../SearchPagination';
import QueryTag from '../filters/QueryTag';
import GeoLocationFilter from '../filters/GeoLocationFilter';
import GeoLocationTags from '../filters/GeoLocationTags';
import SearchConfigure from '../SearchConfigure';
import { authStateAtom } from '../../common/state/state';
import { HistoryIndexUiState, OnStateChangeArgs, currentExpandedAccordionAtom } from '../state/state';
import ContractStatusFilter from '../filters/ContractStatusFilter';
import ContractStatusFilterTag from '../filters/ContractStatusFilterTag';
import FullTextSearch from '../../common/components/FullTextSearch';
import FilterUtils, { BreakpointFilterBackgrounds } from '../filters/filter.utils';
import useCurrentBreakpoint from '../../hooks/useCurrentBreakpoint';
import FiltersDialog from '../filters/FiltersDialog';
import useOpenable from '../../hooks/useOpenable';
import { DiscoveryWordingsKeys } from '../discovery.utils';
import useBaseStore from '../../hooks/useBaseStore';
import { currentContextAtom } from '../../state';
import ClearFiltersButton from '../filters/ClearFiltersButton';
import useDiscoveryDefaultFilters from '../../hooks/useDiscoveryDefaultFilters';
import useUpdateUiState from '../../discovery/hooks/useUpdateHistoryUiState';

const SEARCH_BUYER_PAGE_HITS_PER_PAGE = 36;

export default function SearchBuyerPage(): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(['discovery']);
  const index = import.meta.env.REACT_APP_ALGOLIA_COMPANY_INDEX;
  const { companyGeoloc, companyId } = useAtomValue(currentContextAtom);
  const authInfo = useAtomValue(authStateAtom);
  const searchClient = useSearchClient();
  const breakpoint = useCurrentBreakpoint();
  const baseStore = useBaseStore();
  const { close, isOpen, open } = useOpenable();
  const [fullTextSearchValue, setFullTextSearchValue] = useState((window.history.state.uiState?.[index] as HistoryIndexUiState)?.query || '');
  const setCurrentExpandedAccordion = useSetAtom(currentExpandedAccordionAtom);
  const { initialIndexUiState, updateUiState } = useUpdateUiState({ index: import.meta.env.REACT_APP_ALGOLIA_COMPANY_INDEX });
  const defaultFilters = useDiscoveryDefaultFilters();
  const filters = `${defaultFilters} AND ${FilterUtils.GetSignedContractFilter('partners', companyId)}`;

  const handleStateChange = (args: OnStateChangeArgs): void => {
    updateUiState(args);
    setFullTextSearchValue(args.uiState[index].query || '');
  };

  useEffect(() => {
    setCurrentExpandedAccordion('geolocation');
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!companyGeoloc && authInfo.isAuthenticated && (
        <Provider store={baseStore}>
          <PageWrapper
            sx={{
              backgroundColor: 'background.paper',
              flex: 1,
              pt: 0,
              [theme.breakpoints.down('sm')]: {
                px: 0,
              },
            }}
          >
            <InstantSearch
              indexName={index}
              searchClient={searchClient}
              initialUiState={{
                [index]: initialIndexUiState,
              }}
              onStateChange={handleStateChange}
            >
              <SearchConfigure filters={filters} index={index} hitsPerPage={SEARCH_BUYER_PAGE_HITS_PER_PAGE}>
                <Box
                  sx={{
                    p: {
                      xs: 1,
                      sm: 2,
                      lg: 3,
                    },
                    pb: {
                      xs: 0,
                      sm: 1,
                      lg: 2,
                    },
                    borderRadius: {
                      xs: 0,
                      sm: 4,
                    },
                    backgroundImage: `url(${BreakpointFilterBackgrounds[breakpoint]})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="headlineMedium"
                    sx={{
                      paddingBottom: 2,
                      textAlign: 'center',
                      [theme.breakpoints.down('md')]: {
                        display: 'none',
                      },
                    }}
                  >
                    {t(DiscoveryWordingsKeys.SUPPLIER.header)}
                  </Typography>
                  <Grid2 container sx={{ maxWidth: 1200, flexWrap: 'nowrap' }}>
                    <Grid2
                      size={{ md: 8 }}
                      sx={{
                        display: {
                          xs: 'none',
                          md: 'flex',
                        },
                        justifyContent: 'center',
                        alignItems: 'center',
                        px: 2,
                        mr: 2,
                        backgroundColor: 'white',
                        borderRadius: 4,
                        '& > .MuiDivider-root': {
                          mx: 1,
                        },
                      }}
                    >
                      <GeoLocationFilter variant="text" index={import.meta.env.REACT_APP_ALGOLIA_COMPANY_INDEX} />
                      <Divider orientation="vertical" sx={{ height: '32px' }} />
                      <ContractStatusFilter attribute="partners" variant="text" />
                    </Grid2>
                    <Grid2 size={{ xs: 6, md: 4 }}>
                      <FullTextSearch
                        label={t(DiscoveryWordingsKeys.SUPPLIER.search_by_name)}
                        inputProps={{
                          placeholder: t('discovery:search_buyers'),
                        }}
                        value={fullTextSearchValue}
                      />
                    </Grid2>
                    <Grid2
                      size={{ xs: 6 }}
                      sx={{
                        display: {
                          xs: 'flex',
                          md: 'none',
                        },
                      }}
                    >
                      <Button
                        startIcon={<TuneIcon />}
                        variant="outlined"
                        color="secondary"
                        sx={{
                          backgroundColor: 'background.paper',
                          width: '100%',
                          ml: {
                            xs: 1,
                            sm: 2,
                          },
                          borderRadius: 4,
                          borderColor: 'divider',
                          justifyContent: 'center',
                          '&:hover': {
                            backgroundColor: 'white',
                          },
                          whiteSpace: 'nowrap',
                        }}
                        onClick={open}
                      >
                        {t('discovery:display_filters')}
                      </Button>
                      <FiltersDialog open={isOpen} onClose={close}>
                        <GeoLocationFilter mode="ACCORDION" index={import.meta.env.REACT_APP_ALGOLIA_COMPANY_INDEX} />
                        <ContractStatusFilter attribute="partners" mode="ACCORDION" />
                      </FiltersDialog>
                    </Grid2>
                  </Grid2>
                  <Box
                    sx={{
                      pt: 2,
                      backgroundColor: 'palette.background',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                      justifyContent: 'center',
                    }}
                  >
                    <GeoLocationTags variant="outlined" size="medium" />
                    <QueryTag variant="outlined" size="medium" />
                    <ContractStatusFilterTag attribute="partners" variant="outlined" size="medium" />
                    <ClearFiltersButton
                      attributesToCheck={[
                        'product_families',
                        'administrative_areas',
                      ]}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: 'background.paper',
                    py: 2,
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'start',
                  }}
                >
                  <SearchBuyerHits />
                  <SearchPagination />
                </Box>
              </SearchConfigure>
            </InstantSearch>
          </PageWrapper>
        </Provider>
      )}
    </>
  );
}
