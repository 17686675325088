import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface OrderCancellationDialogProps {
  open: boolean;
  onClose: (value: boolean, comment?: string) => void;
}

export default function OrderCancellationDialog({ open, onClose }: OrderCancellationDialogProps): React.JSX.Element {
  const [comment, setComment] = useState<string>();
  const { t } = useTranslation(['order']);

  const handleCancel = (): void => {
    onClose(false);
  };
  const handleConfirm = (): void => {
    onClose(true, comment);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setComment(event.target.value);
  };

  return (
    <Dialog
      sx={{
        mx: {
          xs: 2,
          sm: 0,
        },
        '& .MuiPaper-root': {
          height: 530,
          minWidth: {
            xs: '100%',
            sm: 600,
            md: 724,
            lg: 800,
          },
        },
      }}
      open={open}
    >
      <DialogTitle>
        <Typography variant="displayMedium">
          {t('order:cancel_order')}
        </Typography>
        <Divider sx={{ mt: 3, mb: 1 }} />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        <Typography variant="bodyMedium" color="secondary">
          {t('order:cancel_order_message')}
        </Typography>
        <Typography variant="titleMedium">
          {t('order:leave_a_message')}
        </Typography>
        <TextField
          inputProps={{
            style: {
              height: 164,
            },
          }}
          InputLabelProps={{
            style: {
              height: 164,
            },
          }}
          sx={{
            mx: 'auto',
            width: '100%',
          }}
          multiline
          placeholder={t('order:cancel_comment_placeholder')}
          value={comment}
          onChange={handleCommentChange}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="inherit" variant="text">
          {t('common:quit')}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="error"
        >
          {t('order:cancel_order')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
