import { Box, Card, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import EditIcon from '@mui/icons-material/Edit';
import { Packaging_Insert_Input } from 'kheops-graphql';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import TrumpeterButterflyImage from '../../assets/images/trumpeter_butterfly.png';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import { PackagingsByCompanyDocument } from '../../queries/__generated__/packagingsByCompany.generated';
import { companyPackagingsAtom } from '../state';
import { useInsertPackagingsMutation } from '../../mutations/__generated__/insertPackagings.generated';
import { DraftPackagingsPrices } from './state';
import PackagingsPricesForm from './PackagingsPricesForm';
import usePackagingsPricesFormDefaultValues from './usePackagingsPricesFormDefaultValues';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import EmptyPagePlaceholder from '../../common/components/EmptyPagePlaceholder';
import { RoutePaths } from '../../routes/AppRoutes';

export default function CompanyPackagingsPrices(): React.JSX.Element {
  const { t } = useTranslation(['price', 'products', 'contracts']);
  const companyPackagings = useAtomValue(companyPackagingsAtom);
  const { defaultValues, ready } = usePackagingsPricesFormDefaultValues();
  const form = useForm<DraftPackagingsPrices>({
    defaultValues,
    mode: 'onChange',
  });
  const {
    formState: {
      isDirty,
      isValid,
    },
  } = form;

  // After refresh form does not update itself with recent formDefaultValues
  // In order to avoid having empty fields the form is manually reset after each
  // formDefaultValues update.

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [insertPackagings, { loading, data: insertResult }] = useInsertPackagingsMutation({ refetchQueries: [PackagingsByCompanyDocument], awaitRefetchQueries: true });

  const onSubmit = (values: DraftPackagingsPrices): void => {
    const packagingsToEdit = companyPackagings.reduce<Packaging_Insert_Input[]>((dataToSave, packaging) => {
      const packagingPriceFromForm = values.prices![packaging.sku.toString()].packagingPrice;

      if (packaging.price !== packagingPriceFromForm) {
        const packagingData = {
          ...packaging,
          __typename: undefined,
          id: undefined,
          base_unit: undefined,
          product: undefined,
          price: packagingPriceFromForm,
        };

        dataToSave.push(packagingData);
      }

      return dataToSave;
    }, []);

    insertPackagings({
      variables: {
        packagings: packagingsToEdit,
      },
    });
    form.reset(undefined, { keepValues: true });
  };

  useEffect(() => {
    if (insertResult?.insert_packaging?.returning.length) {
      setCommonSnackbarProps({
        label: t('common:update_successful'),
        snackbarProps: {
          open: true,
        },
      });
    }
  }, [insertResult]);

  return (
    <Card
      sx={{
        display: 'flex',
        height: {
          lg: 'calc(100vh - 168px)',
          xs: 'calc(100vh - 358px)',
        },
      }}
    >
      <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <DiscountIcon height={28} width={28} />
          <Typography variant="titleLarge">
            {t('price:prices')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="titleMedium">
            {t('contracts:default_price')}
          </Typography>
          <Typography variant="bodyMedium" color="secondary">
            {t('contracts:default_price_description')}
          </Typography>
        </Box>
        {
          ready && !Object.keys(defaultValues.prices).length
            ? (
              <EmptyPagePlaceholder
                title={t('products:no_product')}
                description={t('products:no_product_description')}
                imageSrc={TrumpeterButterflyImage}
                sx={{ pt: 0 }}
                linkDestination={RoutePaths.PRODUCT_ADD_INFO}
                buttonLabel={t('products:add_a_product')}
                buttonProps={{
                  startIcon: <AddIcon />,
                }}
              />
            )
            : (
              <FormProvider {...form}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                  component="form"
                  onSubmit={form.handleSubmit(onSubmit)}
                >
                  <PackagingsPricesForm />
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    type="submit"
                    disabled={!isDirty || !isValid}
                    startIcon={<EditIcon />}
                    sx={{
                      mt: 3,
                      alignSelf: 'center',
                    }}
                  >
                    {t('price:update_prices')}
                  </LoadingButton>
                </Box>
              </FormProvider>
            )
        }
      </Box>
    </Card>
  );
}
