import { Box, Button, Card, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import GuitaristButterflyImage from '../../assets/images/guitarist_butterfly.png';
import TrumpeterButterflyImage from '../../assets/images/trumpeter_butterfly.png';
import { useCatalogsByCompanyIdQuery } from '../../queries/__generated__/catalogsByCompanyId.generated';
import { currentContextAtom } from '../../state';
import EmptyPagePlaceholder from '../../common/components/EmptyPagePlaceholder';
import { RoutePaths } from '../../routes/AppRoutes';
import CatalogInfoBox from './CatalogInfoBox';

export default function CatalogsList(): React.JSX.Element {
  const { t } = useTranslation(['price', 'products']);
  const { companyId } = useAtomValue(currentContextAtom);
  const { data: catalogsData, loading, called } = useCatalogsByCompanyIdQuery({
    variables: {
      companyId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const emptyPlaceholder = useMemo((): React.JSX.Element | undefined => {
    if (loading || !called) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    if (!catalogsData?.packaging_aggregate?.aggregate?.count) {
      return (
        <EmptyPagePlaceholder
          title={t('products:no_product')}
          description={t('products:no_product_description')}
          imageSrc={TrumpeterButterflyImage}
          sx={{ pt: 0 }}
          linkDestination={RoutePaths.PRODUCT_ADD_INFO}
          buttonLabel={t('products:add_a_product')}
          buttonProps={{
            startIcon: <AddIcon />,
          }}
        />
      );
    }

    if (!catalogsData?.catalog.length) {
      return (
        <EmptyPagePlaceholder
          title={t('price:no_prices_catalog')}
          imageSrc={GuitaristButterflyImage}
          sx={{ pt: 0 }}
          linkDestination={RoutePaths.CATALOG}
          buttonLabel={t('price:create_catalog')}
          buttonProps={{
            startIcon: <AddIcon />,
          }}
        />
      );
    }

    return undefined;
  }, [loading, called, catalogsData]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        overflow: 'auto',
        height: {
          lg: 'calc(100% - 48px)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          alignItems: {
            sm: 'center',
          },
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flex: 1 }}>
          <DiscountIcon height={28} width={28} />
          <Typography variant="titleLarge">
            {t('price:prices')}
          </Typography>
        </Box>
        {!!catalogsData?.catalog.length && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            component={Link}
            to={RoutePaths.CATALOG}
          >
            {t('price:create_catalog')}
          </Button>
        )}
      </Box>
      <Box>
        <Typography variant="titleMedium">
          {t('price:prices_catalog')}
        </Typography>
        <Typography variant="bodyMedium" color="secondary">
          {t('price:prices_catalog_description')}
        </Typography>
      </Box>
      {
        emptyPlaceholder || (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {catalogsData!.catalog.map((catalog) => (
              <CatalogInfoBox catalog={catalog} key={catalog.id} />
            ))}
          </Box>
        )
      }
    </Card>
  );
}
