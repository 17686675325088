import { Box, Divider, InputAdornment, TextField, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { FormatLogisticalPackaging } from 'kheops-utils';
import { BasketOrder } from '../hooks/useBasketOrders';
import { BasketOrderPrice } from './BasketOrderPrice';
import { currentOrdersCommentsAtom, currentOrdersDeliveryDatesAtom } from './state/state';
import BasketItemSummary from './BasketItemSummary';
import DeliveryDatePicker from '../common/components/DeliveryDatePicker';
import { contractByReferenceIdAtom } from '../state';

type BasketOrderSummaryProps = Pick<BasketOrder, 'items' | 'name' | 'photoUrl'>
  & {
    contractReferenceId: string;
  }

export default function BasketOrderSummary(
  { items, name, photoUrl, contractReferenceId }: BasketOrderSummaryProps,
): React.JSX.Element {
  const { t } = useTranslation(['common', 'order']);

  const [value, setValue] = useState<Date | null>(null);
  const contract = useAtomValue(contractByReferenceIdAtom(contractReferenceId));
  const updateCurrentOrdersDeliveryDates = useSetAtom(currentOrdersDeliveryDatesAtom);
  const updateCurrentOrdersComments = useSetAtom(currentOrdersCommentsAtom);

  const handleDeliveryDateChange = (newValue: Date | null): void => {
    setValue(newValue);
    updateCurrentOrdersDeliveryDates({ contractId: contractReferenceId, deliveryDate: newValue });
  };

  const handleCommentChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;
    updateCurrentOrdersComments({ contractId: contractReferenceId, comment: input.length ? input : null });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
        <Box
          component="img"
          src={photoUrl}
          alt={t('common:company')}
          sx={{ borderRadius: 3, width: 56, height: 56 }}
        />
        <Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography variant="bodyMedium">
              {name} ({items.length})
            </Typography>
          </Box>
          <BasketOrderPrice items={items} />
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        {
          items
            .sort(({ packaging: pa }, { packaging: pb }) => {
              const packagingNameA = `${pa.product!.name}_${FormatLogisticalPackaging(pa, 'fr', pa.base_unit!)}`;
              const packagingNameB = `${pb.product!.name}_${FormatLogisticalPackaging(pb, 'fr', pb.base_unit!)}`;

              return packagingNameA.localeCompare(packagingNameB);
            })
            .map((item) => (
              <Box
                key={item.packaging.id}
                sx={{
                  pt: 3,
                }}
              >
                <BasketItemSummary item={item} editable priceVariant="labelLarge" />
              </Box>
            ))
        }
      </Box>
      <Divider sx={{ my: 3 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="titleSmall">{t('order:delivery_date')}</Typography>
          <DeliveryDatePicker companyId={contract!.buyingCompanyId} value={value} onChange={handleDeliveryDateChange} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="titleSmall">{t('comments:comments', { count: 1 })}</Typography>
          <TextField
            variant="outlined"
            multiline
            placeholder={t('comments:add_a_comment')}
            onChange={handleCommentChange}
            rows={3}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ alignSelf: 'baseline', alignItems: 'baseline' }}>
                  <CommentOutlinedIcon sx={{ width: 20, height: 20 }} color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
