import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from '../../../routes/AppRoutes';
import BackButton from '../../../common/components/BackButton';

export interface CatalogFormHeaderProps {
  edit?: boolean;
}

export default function CatalogFormHeader({ edit }: CatalogFormHeaderProps): React.JSX.Element {
  const { t } = useTranslation(['price']);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <BackButton
          to={RoutePaths.COMPANY_CATALOGS}
          compact
          color="secondary.light"
        />
        <Typography variant="titleLarge" sx={{ ml: 1 }}>
          {t(edit ? 'price:edit_your_catalog' : 'price:create_catalog')}
        </Typography>
      </Box>
      <Typography variant="bodyMedium" color="secondary">
        {t('price:prices_catalog_description')}
      </Typography>
    </Box>
  );
}
