import { Box, Button, Checkbox, Chip, Divider, FormHelperText, Grid2, Input, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAtom, useSetAtom } from 'jotai';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Duration_Type_Enum, Product_Label_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { PackagingFamilyTypeIntl } from '../../../common/i18n/product-family.translation';
import { logoLabels } from '../../../packaging/packaging-formatter';
import { RoutePaths } from '../../../routes/AppRoutes';
import { DraftProduct, draftProductAtom, productSubmittedAtom } from '../state';
import InvalidFormBanner from '../InvalidFormBanner';
import useOpenable from '../../../hooks/useOpenable';
import useSortedProductFamiliesMap from '../../../hooks/useSortedProductFamiliesMap';
import ProductFormQuitBtn from '../ProductFormQuitBtn';
import NutritionFactsForm from './NutritionFactsForm';
import { useNonFoodProductsSubFamiliesQuery } from '../../../queries/__generated__/nonFoodProductsSubFamilies.generated';

export default function ProductFormInfo(): React.JSX.Element {
  const { data: nonFoodProductFamiliesData } = useNonFoodProductsSubFamiliesQuery();
  const form = useForm<DraftProduct>({
    defaultValues: {
      name: '',
      sub_family: '' as Product_Sub_Family_Name_Enum,
      labels: [] as Product_Label_Name_Enum[],
      vat_rate: 0,
      ingredients: '',
      shelf_life_duration_type: Duration_Type_Enum.Days,
      allergens: '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      calories: '' as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      calories_kj: '' as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fat: '' as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      saturated_fat: '' as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      carbohydrate: '' as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sugars: '' as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sodium: '' as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      protein: '' as any,
      description: '',
    },
  });
  const {
    control,
    handleSubmit,
    formState: {
      submitCount,
      isValid,
      isDirty,
      isSubmitSuccessful,
    },
    watch,
    setValue,
    reset,
  } = form;
  const watchLabels = watch('labels', []);
  const watchProductFamily = watch('sub_family');
  const { t } = useTranslation(['common', 'products', 'product-family', 'validation']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [draftProduct, setDraftProduct] = useAtom(draftProductAtom);
  const {
    isOpen: isInvalidFormBannerOpen,
    open: openInvalidFormBanner,
    close: closeInvalidFormBanner,
  } = useOpenable();
  const setProductSubmitted = useSetAtom(productSubmittedAtom);
  const productFamiliesMap = useSortedProductFamiliesMap(Object.keys(PackagingFamilyTypeIntl) as Product_Sub_Family_Name_Enum[]);
  const productId = searchParams.get('productId');

  const onSubmit = (data: DraftProduct): void => {
    setDraftProduct(data);

    if (productId) {
      setProductSubmitted(true);
      reset(undefined, { keepValues: true });
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful && !productId) {
      navigate({
        pathname: RoutePaths.PRODUCT_ADD_PACKAGING,
        search: `?${searchParams.toString()}`,
      });
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (submitCount && !isValid) {
      openInvalidFormBanner();
    }
  }, [submitCount]);

  useEffect(() => {
    if (draftProduct) {
      Object.entries(draftProduct).forEach(([key, value]) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setValue(key as any, value);
      });
      reset(undefined, { keepValues: true });
    }
  }, [draftProduct]);

  const familyValues = useMemo(() => {
    return productFamiliesMap
      .map(({ value, label }) =>
        <MenuItem value={value} key={value}>{label}</MenuItem>);
  }, [PackagingFamilyTypeIntl]);

  const labelValues = useMemo(() => {
    return (Object.keys(logoLabels) as Product_Label_Name_Enum[])
      .map((label) => ({ ...logoLabels[label], label }))
      .sort((a, b) => a.alt.localeCompare(b.alt))
      .map(({ label, alt }) => (
        <MenuItem value={label} key={label}>
          <Checkbox checked={watchLabels.indexOf(label) > -1} />
          <ListItemText primary={alt} />
        </MenuItem>
      ));
  }, [watchLabels]);

  const renderLabelValues = (selected: Product_Label_Name_Enum[]): React.JSX.Element => {
    if (!selected.length) {
      return <Typography color="secondary">{t('products:certifications_and_qualities_placeholder')}</Typography>;
    }
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected.map((value) => (
          <Chip key={value} label={logoLabels[value].alt} size="small" />
        ))}
      </Box>
    );
  };

  const vatRateValues = useMemo(
    () =>
      [2.1, 5.5, 10, 20].map((vatRate) => <MenuItem value={vatRate / 100} key={vatRate}>{vatRate}%</MenuItem>),
    [],
  );

  const shelfLifeDurationTypes = useMemo(() => (
    [
      <MenuItem value={Duration_Type_Enum.Days}>{t('common:day_s')}</MenuItem>,
      <MenuItem value={Duration_Type_Enum.Months}>{t('common:months').toLowerCase()}</MenuItem>,
      <MenuItem value={Duration_Type_Enum.Years}>{t('common:year_s')}</MenuItem>,
    ]
  ), []);

  const isFoodProduct = useMemo((): boolean => {
    return !watchProductFamily || !nonFoodProductFamiliesData?.product_sub_family?.map((productSubFamily) => productSubFamily.name).includes(watchProductFamily);
  }, [watchProductFamily]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container rowSpacing={4}>
          <Grid2 size={{ xs: 12 }} container columnSpacing={3} rowSpacing={2}>
            <Grid2
              size={{ xs: 12, sm: 'grow' }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="titleMedium">
                {t('products:product_title')}*
              </Typography>
              <Typography variant="bodySmall">
                {t('products:product_title_description')}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, lg: 4 }}>
              <Controller
                name="name"
                control={control}
                rules={{ required: t('validation:this_field_is_required') as string }}
                render={({ field, fieldState }) => (
                  <>
                    <TextField
                      size="small"
                      fullWidth
                      name={field.name}
                      value={field.value}
                      inputRef={field.ref}
                      onChange={field.onChange}
                      placeholder={t('products:product_title_placeholder')}
                      error={!!fieldState.error}
                    />
                    {fieldState.error
                          && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
                  </>
                )}
              />
            </Grid2>
          </Grid2>
          <Grid2 size={{ xs: 12 }} container columnSpacing={3} rowSpacing={2}>
            <Grid2
              size={{ xs: 12, sm: 'grow' }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="titleMedium">
                {t('products:family')}*
              </Typography>
              <Typography variant="bodySmall">
                {t('products:family_description')}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, lg: 4 }}>
              <Controller
                name="sub_family"
                control={control}
                rules={{ required: t('validation:this_field_is_required') as string }}
                render={({ field, fieldState }) => (
                  <>
                    <Select
                      size="small"
                      fullWidth
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      displayEmpty
                      renderValue={(selected) => (
                        !selected
                          ? <Typography color="secondary">{t('products:family_placeholder')}</Typography>
                          : t(`product-family:${PackagingFamilyTypeIntl[selected as Product_Sub_Family_Name_Enum]}`)
                      )}
                      error={!!fieldState.error}
                    >
                      {familyValues}
                    </Select>
                    {!!fieldState.error
                          && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
                  </>
                )}
              />
            </Grid2>
          </Grid2>
          <Grid2 size={{ xs: 12 }} container columnSpacing={3} rowSpacing={2}>
            <Grid2
              size={{ xs: 12, sm: 'grow' }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="titleMedium">
                {t('products:certifications_and_qualities')}
              </Typography>
              <Typography variant="bodySmall">
                {t('products:certifications_and_qualities_description')}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, lg: 4 }}>
              <Controller
                name="labels"
                control={control}
                render={({ field }) => (
                  <Select
                    size="small"
                    fullWidth
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    multiple
                    displayEmpty
                    renderValue={renderLabelValues}
                  >
                    {labelValues}
                  </Select>
                )}
              />
            </Grid2>
          </Grid2>

          {isFoodProduct && (
            <Grid2 size={{ xs: 12 }} container columnSpacing={3} rowSpacing={2}>
              <Grid2
                size={{ xs: 12, sm: 'grow' }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="titleMedium">
                  {t('products:shelf_life')}
                </Typography>
                <Typography variant="bodySmall">
                  {t('products:shelf_life_description')}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, lg: 4 }}>
                <Box
                  sx={{
                    display: 'flex',
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    '&:hover': {
                      borderColor: 'text.primary',
                      '& hr': {
                        borderColor: 'text.primary',
                      },
                    },
                    '&:focus-within': {
                      borderColor: 'text.primary',
                      boxShadow: 'inset 0 0 0 1px',
                      '& hr': {
                        borderColor: 'text.primary',
                        borderWidth: '1px',
                      },
                    },
                    borderRadius: 2,
                    height: 40,
                    gap: 2,
                    pl: 1,
                  }}
                >
                  <Controller
                    name="shelf_life_duration"
                    control={control}
                    rules={{
                      pattern: /^\d+$/,
                      validate: (v) => v === null || v === undefined || v > 0,
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <Input
                          fullWidth
                          type="number"
                          disableUnderline
                          placeholder={t('products:shelf_life')}
                          sx={{
                            borderLeft: 0,
                            borderWidth: 0,
                            '& input[type=number]::-webkit-outer-spin-button': {
                              display: 'none',
                            },
                            '& input[type=number]::-webkit-inner-spin-button': {
                              display: 'none',
                            },
                          }}
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                        />
                        {!!fieldState.error && <FormHelperText sx={{ position: 'absolute', mt: 6 }} error>{t('error:number_must_be_whole_and_positive')}</FormHelperText>}
                      </>
                    )}
                  />
                  <Divider orientation="vertical" flexItem />
                  <Controller
                    name="shelf_life_duration_type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        variant="standard"
                        size="small"
                        MenuProps={{ disablePortal: true }}
                        sx={{
                          '& .MuiInput-input:focus': {
                            backgroundColor: 'white',
                          },
                        }}
                        renderValue={(value) => (<Typography sx={{ mt: 0.5 }}>{t(`common:${value?.toLowerCase()}`, { count: 2 })}</Typography>)}
                        disableUnderline
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                      >
                        {shelfLifeDurationTypes}
                      </Select>
                    )}
                  />
                </Box>
              </Grid2>
            </Grid2>
          )}
          <Grid2 size={{ xs: 12 }} container columnSpacing={3} rowSpacing={2}>
            <Grid2
              size={{ xs: 12, sm: 'grow' }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="titleMedium">
                {t('products:vat_rate')}*
              </Typography>
              <Typography variant="bodySmall">
                {t('products:vat_rate_description')}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, lg: 4 }}>
              <Controller
                name="vat_rate"
                control={control}
                rules={{ validate: {
                  required: (value) => !!value || (t('validation:this_field_is_required') as string),
                } }}
                render={({ field, fieldState }) => (
                  <>
                    <Select
                      size="small"
                      fullWidth
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      displayEmpty
                      renderValue={(selected) => (
                        !selected
                          ? <Typography color="secondary">{t('products:vat_rate_placeholder')}</Typography>
                          : `${selected * 100}%`
                      )}
                      error={!!fieldState.error}
                    >
                      {vatRateValues}
                    </Select>
                    {!!fieldState.error
                          && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
                  </>
                )}
              />
            </Grid2>
          </Grid2>
          <Grid2 size={{ xs: 12 }} container columnSpacing={3} rowSpacing={2}>
            <Grid2
              size={{ xs: 12, sm: 'grow' }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="titleMedium">
                {t('products:ingredient_list')}
              </Typography>
              <Typography variant="bodySmall">
                {t('products:ingredient_list_description')}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, lg: 4 }}>
              <Controller
                name="ingredients"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <TextField
                      size="small"
                      fullWidth
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t('products:ingredient_list_placeholder')}
                      multiline
                      error={!!fieldState.error}
                    />
                    {fieldState.error
                      && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
                  </>
                )}
              />
            </Grid2>
          </Grid2>
          <Grid2 size={{ xs: 12 }} container columnSpacing={3} rowSpacing={2}>
            <Grid2
              size={{ xs: 12, sm: 'grow' }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="titleMedium">
                {t('products:allergen_list')}
              </Typography>
              <Typography variant="bodySmall">
                {t('products:allergen_list_description')}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, lg: 4 }}>
              <Controller
                name="allergens"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <TextField
                      size="small"
                      fullWidth
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t('products:allergen_list_placeholder')}
                      multiline
                      error={!!fieldState.error}
                    />
                    {fieldState.error
                      && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
                  </>
                )}
              />
            </Grid2>
          </Grid2>
          <Grid2 container size={{ xs: 12 }} rowSpacing={2}>
            <Grid2
              size={{ xs: 12 }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="titleMedium">
                {t('products:product_description')}
              </Typography>
              <Typography variant="bodySmall">
                {t('products:product_description_details')}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <OutlinedInput
                      fullWidth
                      inputProps={{
                        style: {
                          minHeight: 80,
                        },
                      }}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t('products:add_description')}
                      multiline
                      error={!!fieldState.error}
                    />
                    {fieldState.error
                      && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
                  </>
                )}
              />
            </Grid2>
          </Grid2>
          {isFoodProduct && <NutritionFactsForm />}
          {isInvalidFormBannerOpen && (
            <Grid2
              size={{ xs: 12 }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <InvalidFormBanner close={closeInvalidFormBanner} message={t('validation:fill_the_marked_field_to_continue')} />
            </Grid2>
          )}
          <Grid2
            size={{ xs: 12 }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ProductFormQuitBtn productId={productId || undefined} shouldBlock={isDirty && !isSubmitSuccessful} />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!!productId && !isDirty}
            >
              {productId ? t('common:validate') : t('common:next')}
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </FormProvider>
  );
}
