import { Box, Button, Grid2, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RoutePaths } from '../../routes/AppRoutes';
import { DraftProductPackagings, draftProductAtom, draftProductPackagingsAtom, editModeAtom, productSubmittedAtom } from './state';
import useOpenable from '../../hooks/useOpenable';
import InvalidFormBanner from './InvalidFormBanner';
import ProductCSUForm from './packagings/ProductCSUForm';
import ProductFormQuitBtn from './ProductFormQuitBtn';
import { useProductAddPageOutletContext } from './ProductAdd';
import usePackagingsFormDefaultValues, { productPackagingDefaultValue } from '../hooks/usePackagingsFormDefaultValues';

export interface ProductPackagingsFormData {
  productPackagings: DraftProductPackagings[];
}

export default function ProductFormPackagings(): React.JSX.Element {
  const { t } = useTranslation(['common', 'products', 'validation']);
  const navigate = useNavigate();
  const { insertProductOutput } = useProductAddPageOutletContext();
  const [draftProductPackagings, setDraftProductPackagings] = useAtom(draftProductPackagingsAtom);
  const packagingsFormDefaultValues = usePackagingsFormDefaultValues();
  const form = useForm<ProductPackagingsFormData>({
    defaultValues: {
      productPackagings: packagingsFormDefaultValues,
    },
  });
  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: 'productPackagings',
  });
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('productId');
  const baseUnitSku = parseInt(searchParams.get('baseUnitSku') || '', 10);
  const editMode = useAtomValue(editModeAtom);
  const draftProduct = useAtomValue(draftProductAtom);
  const {
    isOpen: isInvalidFormBannerOpen,
    open: openInvalidFormBanner,
    close: closeInvalidFormBanner,
  } = useOpenable();
  const [errorMessage, setErrorMessage] = useState('');
  const [isDirty, setIsDirty] = useState(false);

  // react-hook-form considers that our form is dirty even though none of its fields are dirty.
  // This seems to be an issue caused by the use of field array.
  // A common workaround is to no longer rely on the formState.isDirty property, but to count the number of dirty fields.
  useEffect(() => {
    setIsDirty(Object.keys(form.formState.dirtyFields).length > 0);
  }, [Object.keys(form.formState.dirtyFields).length]);

  const handleDeleteCSU = useCallback((index: number) => {
    remove(index);
  }, []);

  const productBaseUnitForms = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (editMode) {
      case 'CREATE':
        return fields.map(({ id }, index) => <ProductCSUForm onDelete={handleDeleteCSU} key={id} index={index} />);
      case 'ADD':
        return <ProductCSUForm index={fields.length - 1} />;
      case 'EDIT':
        // eslint-disable-next-line no-case-declarations
        const baseUnitIndex = draftProductPackagings.findIndex((packaging) => packaging.csu.sku === baseUnitSku);

        return <ProductCSUForm index={baseUnitIndex} />;
    }
  }, [fields, draftProductPackagings, editMode]);
  const setProductSubmitted = useSetAtom(productSubmittedAtom);

  const onSubmit = (data: ProductPackagingsFormData): void => {
    setDraftProductPackagings(data.productPackagings);
    setProductSubmitted(true);

    if (productId) {
      form.reset(undefined, { keepValues: true });
    }
  };

  const handleAddCSU = (): void => {
    append(productPackagingDefaultValue);
  };

  useEffect(() => {
    if (!draftProduct) {
      navigate(RoutePaths.PRODUCT_ADD_INFO, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (form.formState.submitCount && !form.formState.isSubmitSuccessful) {
      openInvalidFormBanner();
      setErrorMessage(t('validation:fill_the_marked_field_to_continue'));
    }
  }, [form.formState.submitCount]);

  useEffect(() => {
    if (insertProductOutput.error?.graphQLErrors[0].extensions?.code === 'constraint-violation') {
      setErrorMessage(t('products:you_have_already_referenced_a_csu_with_given_gtin'));
      openInvalidFormBanner();
    }
  }, [insertProductOutput]);

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
          {productBaseUnitForms}
        </Box>
        {editMode === 'CREATE' && (
          <Box
            sx={{
              mt: 4,
              p: 3,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 4,
              textAlign: 'center',
            }}
          >
            <Typography variant="titleLarge">
              {t('products:you_have_more_base_unit')}
            </Typography>
            <Button
              variant="outlined"
              onClick={handleAddCSU}
              startIcon={<AddIcon />}
              sx={{
                px: {
                  xs: 2,
                  sm: 7,
                },
                mt: 3,
              }}
            >
              {t('products:add_a_base_unit')}
            </Button>
          </Box>
        )}
        <Grid2 container>
          {isInvalidFormBannerOpen && (
            <Grid2
              size={{ xs: 12 }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 4,
              }}
            >
              <InvalidFormBanner close={closeInvalidFormBanner} message={errorMessage} />
            </Grid2>
          )}
          <Grid2
            size={{ xs: 12 }}
            sx={{
              mt: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ProductFormQuitBtn productId={productId || undefined} shouldBlock={isDirty && !form.formState.isSubmitSuccessful} />
            { !productId && (
              <Button
                variant="outlined"
                component={Link}
                to={{
                  pathname: RoutePaths.PRODUCT_ADD_INFO,
                  search: `?${searchParams.toString()}`,
                }}
                sx={{ mr: 2 }}
              >
                {t('common:previous')}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!!productId && !isDirty}
            >
              {t('common:validate')}
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </FormProvider>
  );
}
