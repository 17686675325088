import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Box, DialogContent, IconButton, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { computeIntraCommunityVatFR } from 'kheops-utils';
import { BuyerInfoViewProps } from './BuyerInfoDialog';
import Address from '../address/Address';
import CompanyContactList from '../../CompanyContactList';
import useResolvedAdditionalAddress from '../address/hook/useResolvedAdditionalAddress';

type CategoryName = 'address' | 'contact';

export default function BuyerInfoInvoicingView({ company, close, changeView, hideBackArrow }: BuyerInfoViewProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts']);
  const invoicingAddress = useResolvedAdditionalAddress({ companyName: company.legalName, companyAddress: company.addresses[0], additionalAddress: company.invoicing_address });
  const [currentExpandedAccordion, setCurrentExpandedAccordion] = useState<CategoryName | undefined>('address');

  const invoicingContacts = useMemo(() => {
    return company.role_assignments.filter(({ is_invoicing_contact }) => is_invoicing_contact).map(({ user }) => user);
  }, [company]);

  const toggleAccordion = (name: CategoryName): void => {
    setCurrentExpandedAccordion(currentExpandedAccordion === name ? undefined : name);
  };

  return (
    <DialogContent
      sx={{
        maxWidth: 500,
        height: 620,
        p: 2,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="secondary"
            onClick={() => changeView('GENERAL')}
            sx={{
              backgroundColor: 'greys.primary',
              display: hideBackArrow ? 'none' : undefined,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <ReceiptLongOutlinedIcon sx={{ mx: 1 }} />
          <Typography variant="titleMedium" sx={{ flex: 1 }}>
            {t('common:billing')}
          </Typography>
          <IconButton color="secondary" onClick={close} sx={{ backgroundColor: 'greys.primary' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="bodySmall" color="secondary">
          {t('contracts:buyer_invoicing_information')}
        </Typography>
      </Box>
      <Box
        sx={{
          overflow: 'auto',
          '& > *:not(:last-child)': {
            borderBottom: '1px solid',
            borderColor: 'divider',
          },
        }}
      >
        <Accordion
          disableGutters
          elevation={0}
          expanded={currentExpandedAccordion === 'address'}
          onChange={() => toggleAccordion('address')}
        >
          <AccordionSummary sx={{ lineHeight: '2rem' }} expandIcon={<ArrowDropDownIcon sx={{ color: 'text.primary' }} />}>
            📍 {t('common:address')}
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 0, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Address
              address={invoicingAddress}
              additionalInfoTitle={t('settings:additional_info')}
              additionalInfo={company.invoicing_instructions}
              email={company.invoicing_email}
              phoneNumber={company.invoicing_phone_number}
              hidePhone
              enableMapLink
            />
            <Box>
              <Typography variant="bodySmall" color="secondary">
                {t('common:national_company_registration_number')}
              </Typography>
              <Typography variant="bodyMedium">
                {company.national_company_registration_number}
              </Typography>
            </Box>
            <Box>
              <Typography variant="bodySmall" color="secondary">
                {t('common:vat_number')}
              </Typography>
              <Typography variant="bodyMedium">
                {computeIntraCommunityVatFR(company.national_company_registration_number)}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          expanded={currentExpandedAccordion === 'contact'}
          onChange={() => toggleAccordion('contact')}
        >
          <AccordionSummary sx={{ lineHeight: '2rem' }} expandIcon={<ArrowDropDownIcon sx={{ color: 'text.primary' }} />}>
            👤 {t('common:contacts')}
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {invoicingContacts.length
              ? (
                <CompanyContactList contacts={invoicingContacts} displayContactInfo />
              ) : (
                <Typography variant="bodySmall" color="secondary" sx={{ px: 2, pt: 1, pb: 2 }}>
                  {t('contracts:no_invoicing_contact_registered')}
                </Typography>
              )}
          </AccordionDetails>
        </Accordion>
        <Typography variant="bodyMedium" sx={{ p: 2 }}>
          📞 {company.invoicing_phone_number || '-'}
        </Typography>
        <Typography variant="bodyMedium" sx={{ p: 2 }}>
          ✉️ {company.invoicing_email || '-'}
        </Typography>
      </Box>
    </DialogContent>
  );
}
