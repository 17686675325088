import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';

export interface ConfirmDialogProps {
  title?: string;
  message?: string | React.JSX.Element;
  children?: React.ReactNode;
  cancelLabel: string;
  confirmLabel: string;
  confirmColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  confirmIcon?: ReactNode;
  open: boolean;
  onClose: (value: boolean) => void;
  waitForClosing?: boolean;
}

export default function ConfirmDialog({
  title,
  message,
  children,
  cancelLabel,
  confirmLabel,
  confirmColor,
  confirmIcon,
  open,
  onClose,
  waitForClosing,
}: ConfirmDialogProps): React.JSX.Element {
  const [loading, setLoading] = useState(false);

  const handleCancel = (): void => {
    onClose(false);
  };

  const handleConfirm = (): void => {
    if (waitForClosing) {
      setLoading(true);
    }

    onClose(true);
  };

  return (
    <Dialog onClose={handleCancel} open={open}>
      {!!title
        && (
        <DialogTitle>
          <Typography variant="displayMedium">{ title }</Typography>
          <Divider sx={{ mt: 3, mb: 1 }} />
        </DialogTitle>
        )}
      <DialogContent>
        {message && <Typography color="secondary" sx={{ textAlign: 'center' }} variant="bodyMedium">{message}</Typography>}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="inherit" variant="text">{ cancelLabel }</Button>
        <LoadingButton
          onClick={handleConfirm}
          startIcon={confirmIcon}
          loading={loading}
          variant="contained"
          color={confirmColor || 'primary'}
        >
          { confirmLabel }
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
