import { Box, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import CSUForm from './CSUForm';
import CSUTypeForm from './CSUTypeForm';
import useOpenable from '../../../hooks/useOpenable';
import { ProductPackagingsFormData } from '../ProductFormPackagings';
import BaseUnitAvailabilityForm from './BaseUnitAvailabilityForm';
import PricingForm from '../price/PricingForm';
import BaseUnitPhotosForm from './photos/BaseUnitPhotosForm';
import PackagingForm from './PackagingForm';
import { draftProductAtom } from '../state';
import DeleteBaseUnitDialog from '../../common/DeleteBaseUnitDialog';
import BaseUnitSuggestedRetailPrice from './BaseUnitSuggestedRetailPrice';

export interface ProductCSUFormProps {
  index: number;
  onDelete?: (index: number) => void;
}

export default function ProductCSUForm({ index, onDelete }: ProductCSUFormProps): React.JSX.Element {
  const { watch } = useFormContext<ProductPackagingsFormData>();
  const { t } = useTranslation(['common', 'products']);
  const draftProduct = useAtomValue(draftProductAtom);
  const watchCSUType = watch(`productPackagings.${index}.csu.csu_type`);
  const watchProductPackagings = watch('productPackagings');
  const { isOpen, open, close } = useOpenable();

  const handleDeleteCSU = (): void => {
    open();
  };

  const handleDeleteConfirm = (confirm: boolean): void => {
    if (confirm) {
      onDelete!(index);
    }

    close();
  };

  return (
    <Box sx={{ rowGap: 3, display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="titleLarge">
          {t('products:your_base_unit')}
        </Typography>
        {!!onDelete && (
          <Button
            variant="outlined"
            color="error"
            disabled={watchProductPackagings.length === 1}
            onClick={handleDeleteCSU}
            startIcon={<DeleteIcon />}
          >
            {t('common:delete')}
          </Button>
        )}
      </Box>
      <CSUTypeForm csuIndex={index} />
      {watchCSUType === 'UNIT' && <CSUForm index={index} />}
      <BaseUnitPhotosForm csuIndex={index} />
      <PackagingForm baseUnitIndex={index} />
      <PricingForm baseUnitIndex={index} />
      <BaseUnitSuggestedRetailPrice baseUnitIndex={index} />
      <BaseUnitAvailabilityForm index={index} />
      {isOpen && (
        <DeleteBaseUnitDialog
          open={isOpen}
          onClose={handleDeleteConfirm}
          subFamily={draftProduct!.sub_family}
          photo={watchProductPackagings[index].csu.main_photo}
        />
      )}
    </Box>
  );
}
