import { Box, Button, Card, Grid2, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import DiscountCardLogo from '../assets/images/discount_card.png';
import MinimumOrderValueChip from '../common/components/MinimumOrderValueChip';
import { CompanyInfo } from './companyInfo/CompanyInfoCard';
import useOpenable from '../hooks/useOpenable';
import ContractConditionsDialog from '../common/components/ContractConditionsDialog';
import { useUpdateContractMutation } from '../mutations/__generated__/updateContract.generated';
import PriceModeChip from '../common/components/PriceModeChip';
import ContractsUtils from '../common/utils/contracts.utils';
import { commonSnackbarPropsAtom } from '../common/state/state';
import { ContractConditions } from '../chats/state';
import { currentContextAtom } from '../state';
import { ContractPreview } from '../hooks/useContracts';
import { ContractsAsBuyerDocument } from '../queries/__generated__/contractsAsBuyer.generated';
import { ContractsAsSupplierDocument } from '../queries/__generated__/contractsAsSupplier.generated';
import PackagingPricesDetails from './prices/PackagingPricesDetails';

export interface ContractInfoCardProps {
  contract: ContractPreview;
  company: CompanyInfo;
}

export default function ContractInfoCard({ contract, company }: ContractInfoCardProps): React.JSX.Element {
  const { t } = useTranslation(['contracts', 'common']);
  const { realm } = useAtomValue(currentContextAtom);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const { open, isOpen, close } = useOpenable();
  const { open: openPackagingDetails, isOpen: isPackagingDetailsOpen, close: closePackagingDetails } = useOpenable();
  const [updateContract, { loading, data }] = useUpdateContractMutation({
    refetchQueries: [ContractsAsBuyerDocument, ContractsAsSupplierDocument],
    awaitRefetchQueries: true,
  });

  const handleContractUpdate = useCallback((response?: Partial<ContractConditions>) => {
    close();

    if (response) {
      updateContract({
        variables: {
          contractId: contract.id,
          contractInput: {
            discount: response.discount,
            minimum_order_value: response.minimum_order_value || 0,
            catalog_id: response.catalog_id,
          },
        },
      });
    }
  }, [contract]);

  useEffect((): void => {
    if (data?.update_contract_by_pk?.id) {
      setCommonSnackbarProps({
        label: t('common:update_successful'),
        snackbarProps: {
          open: true,
        },
      });
    }
  }, [data]);

  return (
    <Card
      sx={{
        backgroundImage: `url(${DiscountCardLogo})`,
        backgroundSize: {
          xs: 160,
          sm: 200,
        },
        backgroundRepeat: 'no-repeat',
        backgroundColor: (theme) => theme.palette.marketing1.main,
        p: 3,
        height: 'calc(100% - 48px)',
      }}
    >
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 6, md: 12 }}>
          <Typography variant="displayLarge" sx={{ width: 110 }}>
            {t('contracts:your_agreement')}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 6, md: 12 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 'fit-content',
              margin: 'auto',
              gap: 1,
              maxWidth: '100%',
            }}
          >
            {ContractsUtils.displayPriceMode(realm!, contract) && (
              <PriceModeChip
                contract={contract}
                chipProps={{
                  sx: {
                    width: '100%',
                  },
                }}
              />
            )}
            <MinimumOrderValueChip
              minimumOrderValue={contract.minimum_order_value!}
              size="medium"
              disableTooltip
              sx={{ width: '100%' }}
              sxChip={{ width: '100%' }}
            />
          </Box>
        </Grid2>
        {realm === Business_Profile_Enum.Supplier && (
          <Grid2 size={{ xs: 12 }} sx={{ textAlign: 'center' }}>
            <LoadingButton
              variant="contained"
              startIcon={<EditIcon fontSize="small" />}
              color="ghost"
              onClick={open}
              loading={loading}
              sx={{
                px: 2,
                width: '100%',
                maxWidth: {
                  md: 193,
                },
              }}
            >
              {t('common:update')}
            </LoadingButton>
            <ContractConditionsDialog
              open={isOpen}
              onClose={handleContractUpdate}
              title={t('contracts:update_your_agreement')}
              description={t('contracts:update_your_agreement_description', { company: company.tradeName })}
              contractId={contract.id}
              conditions={{
                discount: contract.discount,
                minimum_order_value: contract.minimum_order_value,
                catalog_id: contract.catalog_id,
              }}
              commissionRate={contract.supplier_commission_rate as number}
              submitLabel={t('common:validate')}
            />
          </Grid2>
        )}
        {realm === Business_Profile_Enum.Buyer && !!contract.catalog_id && (
          <Grid2 size={{ xs: 12 }}>
            <Button
              variant="contained"
              color="ghost"
              onClick={openPackagingDetails}
              sx={{ width: '100%' }}
            >
              {t('common:see_details')}
            </Button>
            <PackagingPricesDetails
              open={isPackagingDetailsOpen}
              onClose={closePackagingDetails}
              catalogId={contract.catalog_id}
              companyId={company.id}
            />
          </Grid2>
        )}
      </Grid2>
    </Card>
  );
}
