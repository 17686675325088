import { LoadingButton } from '@mui/lab';
import { ButtonProps } from '@mui/material';
import { useEffect } from 'react';
import { Contract_Status_Enum } from 'kheops-graphql';
import useOpenable from '../hooks/useOpenable';
import { useCompanyByIdLazyQuery } from '../queries/__generated__/companyById.generated';
import BlackBackgroundTooltip from '../common/components/BlackBackgroundTooltip';
import ContractRequestDialog from './contractRequest/ContractRequestDialog';

export interface ContractRequestButtonProps {
  toolTipWording: string;
  companyId: string;
  buttonProps?: ButtonProps;
  whenDisableTooltipHover?: boolean;
  contractStatus?: Contract_Status_Enum;
  buttonWording?:string;
}

export default function ContractRequestButton({ contractStatus, companyId, toolTipWording, buttonProps, buttonWording, whenDisableTooltipHover = false }: ContractRequestButtonProps): React.JSX.Element {
  const [companyByIdQuery, { data: companyData, loading }] = useCompanyByIdLazyQuery({
    variables: {
      companyId: companyId!,
    },
  });
  const isContractUnsigned = contractStatus === Contract_Status_Enum.Unsigned;
  const { isOpen: isContactRequestDialogOpen, open: openContactRequest, close: closeContractRequest } = useOpenable();

  const handleClick = (): void => {
    if (companyData?.company_by_pk?.id) {
      openContactRequest();
    } else {
      companyByIdQuery();
    }
  };

  useEffect((): void => {
    if (companyData?.company_by_pk?.id) {
      openContactRequest();
    }
  }, [companyData]);

  return (
    <>
      <BlackBackgroundTooltip
        title={toolTipWording}
        disableHoverListener={whenDisableTooltipHover}
        arrow
        sx={{
          '.MuiTooltip-popper': {
            backgroundColor: 'black',
          },
        }}
      >
        <span>
          <LoadingButton
            loading={loading}
            disabled={isContractUnsigned}
            onClick={() => handleClick()}
            color="primary"
            variant="contained"
            {...buttonProps}
          >
            {buttonWording}
          </LoadingButton>
        </span>
      </BlackBackgroundTooltip>
      {companyData && (
        <ContractRequestDialog
          onCancel={closeContractRequest}
          isOpen={isContactRequestDialogOpen}
          onClose={closeContractRequest}
          companyInfo={companyData!.company_by_pk!}
        />
      )}
    </>
  );
}
