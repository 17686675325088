import { Box, Button, Card, Typography } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import useAuth from '../hooks/useAuth/useAuth';
import BackButton from '../common/components/BackButton';
import { BuyerNavigation, MobileNavigation, SupplierNavigation } from '../common/state/navigation';
import { currentContextAtom } from '../state';
import NavigationSection from './NavigationSection';
import { RoutePaths } from '../routes/AppRoutes';
import ContextImage from './ContextImage';
import { getCompanyShortName } from '../common/utils/common.utils';
import { ellipsisStyle } from '../common/utils/style.utils';

export default function NavigationMain(): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const context = useAtomValue(currentContextAtom);
  const { logout } = useAuth();
  const navigationItems = context.realm === Business_Profile_Enum.Supplier ? SupplierNavigation : BuyerNavigation;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          px: 2,
          backgroundColor: 'white',
          boxSizing: 'border-box',
          width: '100vw',
          height: '72px',
        }}
      >
        <BackButton
          compact
          sx={{
            p: 1,
            width: 40,
            height: 40,
            '& .MuiSvgIcon-root': {
              width: 24,
              height: 24,
            },
          }}
          shouldCheckHistory={false}
          to={RoutePaths.ROOT}
        />
        <ContextImage context={context} size={48} borderRadius={5} />
        <Typography variant="titleMedium" sx={{ ...ellipsisStyle }}>
          {getCompanyShortName(context.companyName, context.realm)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, m: 2 }}>
        <Card sx={{ py: 0, px: 2 }}>
          <NavigationSection items={navigationItems} />
        </Card>
        <Card sx={{ py: 0, px: 2 }}>
          <NavigationSection items={MobileNavigation} />
        </Card>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: 'calc(100% - 32px)',
          p: 2,
        }}
      >
        <Button
          startIcon={<LogoutOutlinedIcon />}
          onClick={logout}
          variant="contained"
          color="ghost"
          size="extraLarge"
          fullWidth
        >
          {t('common:sign_out')}
        </Button>
      </Box>
    </>
  );
}
