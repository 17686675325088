import { ThemeProvider } from '@mui/material';
import { frFR } from '@mui/material/locale';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { renderTimeViewClock } from '@mui/x-date-pickers-pro';
import React from 'react';
import { ErrorPalette, NeutralPalette, NeutralVariantPalette, PrimaryPalette, SecondaryPalette, SuccessPalette, TertiaryPalette, WarningPalette } from '../theme/palettes';

declare module '@mui/material/styles' {
  interface Palette {
    marketing1: Palette['primary'];
    marketing2: Palette['primary'];
    marketing3: Palette['primary'];
    ghost: Palette['primary'];
    greys: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    marketing2?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    extraSmall: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    extraLarge: true;
  }

  interface ButtonPropsColorOverrides {
    marketing1: true;
    marketing2: true;
    ghost: true
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    marketing1: true;
    accent: true;
  }

  interface FabPropsSizeOverrides {
    extraLarge: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    displayLarge: true,
    displayMedium: true,
    displaySmall: true,
    headlineLarge: true;
    headlineMedium: true;
    headlineSmall: true;
    titleLarge: true;
    titleMedium: true;
    titleSmall: true;
    labelLarge: true;
    labelMedium: true;
    labelSmall: true;
    bodyLarge: true;
    bodyMedium: true;
    bodySmall: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    marketing1: true;
    marketing2: true;
    marketing3: true;
    layout: true;
    discount: true;
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    accent: true;
  }
}

export type KheopsTypographyVariant =
  'displayLarge'| 'displayMedium' | 'displaySmall'
  | 'headlineLarge' | 'headlineMedium' | 'headlineSmall'
  | 'titleLarge' | 'titleMedium' | 'titleSmall'
  | 'labelLarge' | 'labelMedium' | 'labelSmall'
  | 'bodyLarge' | 'bodyMedium' | 'bodySmall';

interface CustomThemeProviderProps {
  children: React.ReactNode;
}
export default function CustomThemeProvider({ children }: CustomThemeProviderProps): React.JSX.Element {
  const clearface = "'Clearface', sans-serif";
  const ambit = "'Ambit', sans-serif";
  const colors = {
    black: '#102D2D',
    grey: '#535D5F',
    darkSnow: '#D6D6D6',
    mediumSnow: '#ECEDEE',
    snow: '#F6F7F8',
    primary: '#00B86E',
    gold: '#FFD28F',
    goldLight: '#FEE27A',
    error: '#E7444F',
    schrimp: '#FFC4D4',
    schrimpLight: '#FFDECC',
    tomato: '#FF7051',
    success: '#00B649',
    cream: '#FCF2E1',
  };
  const coreTheme = {
    palette: {
      common: { black: colors.black },
      text: {
        primary: NeutralPalette[10],
        secondary: NeutralVariantPalette[30],
        disabled: colors.darkSnow,
      },
      divider: colors.darkSnow,
      primary: {
        main: PrimaryPalette['40'],
        contrastText: PrimaryPalette['100'],
      },
      onPrimary: {
        main: PrimaryPalette['100'],
        contrastText: PrimaryPalette['40'],
      },
      primaryContainer: {
        main: PrimaryPalette['90'],
        contrastText: PrimaryPalette['10'],
      },
      onPrimaryContainer: {
        main: PrimaryPalette['10'],
        contrastText: PrimaryPalette['90'],
      },
      secondary: {
        main: SecondaryPalette['40'],
        contrastText: SecondaryPalette['100'],
      },
      onSecondary: {
        main: SecondaryPalette['100'],
        contrastText: SecondaryPalette['40'],
      },
      secondaryContainer: {
        main: SecondaryPalette['90'],
        contrastText: SecondaryPalette['10'],
      },
      onSecondaryContainer: {
        main: SecondaryPalette['10'],
        contrastText: SecondaryPalette['90'],
      },
      tertiary: {
        main: TertiaryPalette['40'],
        contrastText: TertiaryPalette['100'],
      },
      onTertiary: {
        main: TertiaryPalette['100'],
        contrastText: TertiaryPalette['40'],
      },
      tertiaryContainer: {
        main: TertiaryPalette['90'],
        contrastText: TertiaryPalette['10'],
      },
      onTertiaryContainer: {
        main: TertiaryPalette['10'],
        contrastText: TertiaryPalette['90'],
      },
      error: {
        main: ErrorPalette['40'],
        contrastText: ErrorPalette['100'],
      },
      onError: {
        main: ErrorPalette['100'],
        contrastText: ErrorPalette['40'],
      },
      errorContainer: {
        main: ErrorPalette['90'],
        contrastText: ErrorPalette['10'],
      },
      onErrorContainer: {
        main: ErrorPalette['10'],
        contrastText: ErrorPalette['90'],
      },
      success: {
        main: SuccessPalette['40'],
        contrastText: SuccessPalette['100'],
      },
      onSuccess: {
        main: SuccessPalette['100'],
        contrastText: SuccessPalette['40'],
      },
      successContainer: {
        main: SuccessPalette['90'],
        contrastText: SuccessPalette['10'],
      },
      onSuccessContainer: {
        main: SuccessPalette['10'],
        contrastText: SuccessPalette['90'],
      },
      warning: {
        main: WarningPalette['40'],
        contrastText: WarningPalette['100'],
      },
      onWarning: {
        main: WarningPalette['100'],
        contrastText: WarningPalette['40'],
      },
      warningContainer: {
        main: WarningPalette['90'],
        contrastText: WarningPalette['10'],
      },
      onWarningContainer: {
        main: WarningPalette['10'],
        contrastText: WarningPalette['90'],
      },
      surfaceDim: {
        main: NeutralPalette['87'],
      },
      surface: {
        main: NeutralPalette['98'],
      },
      surfaceBright: {
        main: NeutralPalette['98'],
      },
      surfaceContainerLowest: {
        main: NeutralPalette['100'],
      },
      surfaceContainerLow: {
        main: NeutralPalette['96'],
      },
      surfaceContainer: {
        main: NeutralPalette['94'],
      },
      surfaceContainerHigh: {
        main: NeutralPalette['92'],
      },
      surfaceContainerHighest: {
        main: NeutralPalette['90'],
      },
      onSurface: {
        main: NeutralPalette['10'],
      },
      onSurfaceVariant: {
        main: NeutralVariantPalette['30'],
      },
      outline: {
        main: NeutralVariantPalette['50'],
      },
      outlineVariant: {
        main: NeutralVariantPalette['80'],
      },
      inverseSurface: {
        main: NeutralPalette['20'],
      },
      inverseOnSurface: {
        main: NeutralPalette['95'],
      },
      inversePrimary: {
        main: PrimaryPalette['80'],
      },
      scrim: {
        main: NeutralPalette['0'],
      },
      shadow: {
        main: NeutralPalette['0'],
      },
      marketing1: {
        main: colors.gold,
        dark: '#F5CE94',
        contrastText: colors.black,
      },
      marketing2: {
        main: colors.schrimpLight,
        dark: '#F5D4C2',
        contrastText: colors.black,
      },
      marketing3: {
        main: colors.schrimp,
        contrastText: colors.black,
      },
      accent: {
        main: colors.tomato,
        contrastText: colors.black,
      },
      discount: {
        main: colors.goldLight,
        contrastText: colors.black,
      },
      layout: {
        main: colors.cream,
        contrastText: colors.black,
      },
      ghost: {
        main: '#ffffff',
        dark: colors.snow,
        contrastText: colors.black,
      },
      greys: {
        main: colors.grey,
        primary: colors.snow,
        light: colors.mediumSnow,
        dark: colors.darkSnow,
        contrastText: colors.black,
      },
    },
  } as ThemeOptions;

  const componentsTheme = {
    typography: {
      fontFamily: ambit,
      displayLarge: {
        fontFamily: clearface,
        fontSize: '2rem',
        lineHeight: '2.5rem',
        fontWeight: 750,
      },
      displayMedium: {
        fontFamily: clearface,
        fontSize: '1.75rem',
        lineHeight: '2.25rem',
        fontWeight: 750,
      },
      displaySmall: {
        fontFamily: clearface,
        fontSize: '1.5rem',
        lineHeight: '2rem',
        fontWeight: 750,
      },
      headlineLarge: {
        fontFamily: ambit,
        fontSize: '2rem',
        lineHeight: '2.5rem',
        letterSpacing: 0,
      },
      headlineMedium: {
        fontFamily: ambit,
        fontSize: '1.75rem',
        lineHeight: '2.25rem',
        letterSpacing: 0,
      },
      headlineSmall: {
        fontFamily: ambit,
        fontSize: '1.5rem',
        lineHeight: '2rem',
        letterSpacing: 0,
      },
      titleLarge: {
        fontFamily: ambit,
        fontSize: '1.375rem',
        lineHeight: '1.75rem',
        letterSpacing: 0,
      },
      titleMedium: {
        fontFamily: ambit,
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        letterSpacing: 0.15,
      },
      titleSmall: {
        fontFamily: ambit,
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        letterSpacing: 0.1,
      },
      labelLarge: {
        fontFamily: ambit,
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        letterSpacing: 0.1,
      },
      labelMedium: {
        fontFamily: ambit,
        fontWeight: 600,
        fontSize: '0.75rem',
        lineHeight: '1rem',
        letterSpacing: 0.5,
      },
      labelSmall: {
        fontFamily: ambit,
        fontWeight: 600,
        fontSize: '0.6875rem',
        lineHeight: '1rem',
        letterSpacing: 0.5,
      },
      bodyLarge: {
        fontFamily: ambit,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        letterSpacing: 0.5,
      },
      bodyMedium: {
        fontFamily: ambit,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        letterSpacing: 0.25,
      },
      bodySmall: {
        fontFamily: ambit,
        fontSize: '0.75rem',
        lineHeight: '1rem',
        letterSpacing: 0.4,
      },
    },
    palette: {
      background: {
        default: colors.cream,
      },
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          paperFullScreen: {
            borderRadius: 0,
          },
          paper: {
            borderRadius: '24px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              color: '#535D5F',
              opacity: 1,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: '500',
            fontSize: '1rem',
            borderRadius: '100px',
            textTransform: 'none',
            whiteSpace: 'nowrap',
            '&:hover': { boxShadow: 'none' },
          },
          sizeSmall: {
            fontSize: '0.875rem',
            padding: '0.5rem 1rem',
          },
          contained: { boxShadow: 'none' },
          outlinedPrimary: {
            color: colors.primary,
          },
        },
        variants: [
          {
            props: { size: 'extraLarge' },
            style: ({ theme }) => ({
              padding: `${theme.spacing(1.5)} ${theme.spacing(8)}`,
            }),
          },
        ],
      },
      MuiIconButton: {
        variants: [
          {
            props: { size: 'extraSmall' },
            style: () => ({
              fontSize: '1.125rem',
            }),
          },
        ],
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
        variants: [
          {
            props: { size: 'extraLarge' },
            style: {
              width: 72,
              height: 72,
              '& .MuiSvgIcon-root': {
                fontSize: 28,
              },
            },
          },
        ],
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.black,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused.MuiInputLabel-outlined': {
              color: colors.black,
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderWidth: 0,
            // eslint-disable-next-line max-len
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within':
              { outlineWidth: '0px' },
            '& .MuiDataGrid-columnHeaderTitleContainer': { paddingLeft: '0px' },
            '& .MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end' },
            '& .MuiDataGrid-columnsContainer': { borderBottomColor: 'transparent' },
          },
          cell: {
            color: colors.black,
            borderBottomColor: colors.darkSnow,
          },
          row: {
            cursor: 'pointer',
          },
          columnHeader: {
            color: colors.grey,
            fontSize: '0.75rem',
            '.MuiDataGrid-columnHeaderTitle': { fontWeight: 300 },
            '.MuiBadge-anchorOriginTopRightRectangular': {
              display: 'none',
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '1rem',
            '&.Mui-selected': {
              fontWeight: 700,
            },
          },
          labelIcon: {
            minHeight: 0,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 24,
            backgroundColor: 'background.paper',
            boxShadow: 'none',
            padding: 24,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            textAlign: 'center',
            marginTop: '1.5rem',
            fontSize: '1.5rem',
            fontFamily: clearface,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            paddingBottom: '1.5rem',
            paddingRight: '1.5rem',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paperAnchorBottom: {
            height: 'calc(100% - 88px)',
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            boxShadow: 'none',
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 28,
            height: 16,
            marginRight: 4,
            padding: 0,
            display: 'flex',
            '&:active': {
              '& .MuiSwitch-thumb': {
                width: 15,
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
              },
            },
            '& .MuiSwitch-switchBase': {
              padding: 2,
              '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                  opacity: 1,
                  backgroundColor: colors.primary,
                },
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
              width: 12,
              height: 12,
              borderRadius: 6,
            },
            '& .MuiSwitch-track': {
              borderRadius: 16 / 2,
              opacity: 1,
              backgroundColor: colors.darkSnow,
              boxSizing: 'border-box',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: '0.5rem',
          },
          sizeSmall: {
            fontSize: '0.875rem',
            '& .MuiChip-icon': {
              color: colors.black,
              fontSize: '1rem',
              height: 16,
              width: 16,
            },
          },
          sizeMedium: {
            fontSize: '1rem',
            fontWeight: 600,
            '& .MuiChip-icon': {
              color: colors.black,
              fontSize: '1.25rem',
              height: 18,
              width: 18,
            },
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            '& .MuiAlert-root': {
              borderRadius: '8px',
            },
          },
        },
      },
      MuiAlert: {
        variants: [
          {
            props: { severity: 'success' },
            style: {
              color: 'white',
            },
          },
        ],
      },
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
      MuiDesktopDateTimePicker: {
        defaultProps: {
          viewRenderers: {
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            displayLarge: 'p',
            displayMedium: 'p',
            displaySmall: 'p',
            headlineLarge: 'p',
            headlineMedium: 'p',
            headlineSmall: 'p',
            titleLarge: 'p',
            titleMedium: 'p',
            titleSmall: 'p',
            labelLarge: 'p',
            labelMedium: 'p',
            labelSmall: 'p',
            bodyLarge: 'p',
            bodyMedium: 'p',
            bodySmall: 'p',
          },
        },
        styleOverrides: {
          root: {
            variants: [
              {
                props: { color: 'secondary' },
                style: {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  color: (coreTheme.palette as any).onSurfaceVariant.main,
                },
              },
            ],
          },
        },
      },
    },
  } as ThemeOptions;

  const theme = createTheme(componentsTheme, coreTheme, frFR);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
