import { Box, IconButton, Typography, debounce } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useGroupChannelListContext } from '@sendbird/uikit-react/GroupChannelList/context';
import { useSearchParams } from 'react-router-dom';
import NewChannelIcon from '../assets/icons/new_channel.svg?react';
import SearchInput from '../common/components/SearchInput';
import { currentContextAtom } from '../state';
import ChannelListPreview from './ChannelListPreview';
import ContactableCompanyList from './ContactableCompanyList';
import { channelListQueryParamsAtom, currentChannelAtom, newChannelSlideOpenAtom } from './state';
import { useContractsByCompanyIdQuery } from '../queries/__generated__/contractsByCompanyId.generated';

export default function ChannelList(): React.JSX.Element {
  const { companyId } = useAtomValue(currentContextAtom);
  const { groupChannels, loadMore } = useGroupChannelListContext();
  const { t } = useTranslation(['common', 'chat']);
  const setCurrentChannel = useSetAtom(currentChannelAtom);
  const [channelListQueryParams, setChannelListQueryParams] = useAtom(channelListQueryParamsAtom);
  const { data: contractsData } = useContractsByCompanyIdQuery({ variables: { companyId } });
  const [searchNoResult, setSearchNoResult] = useState(false);
  const [query, setQuery] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultChannelUrl = searchParams.get('channelUrl');

  const setNewChannelSlideOpen = useSetAtom(newChannelSlideOpenAtom);
  const channelListRef = useRef<HTMLInputElement | null>(null);

  const debounceQuery = useMemo(() => {
    return debounce(setQuery, 200);
  }, []);

  const selectChannel = (channel: GroupChannel): void => {
    setCurrentChannel(channel);

    if (defaultChannelUrl) {
      /*
        this is done so that when a user click on another chat then navigate to another page and click on back button of navigator or phone
        the last clicked conversation should be the one to be open, not the one set by default url
      */
      setSearchParams({});
    }
  };

  useEffect(() => {
    if (contractsData) {
      if (!query) {
        setChannelListQueryParams({
          ...channelListQueryParams,
          channelUrlsFilter: undefined,
        });
      } else {
        const channelUrls = contractsData.contract
          .filter(({ buying_company, supplying_company, channel_url }) => {
            return !!channel_url
              && (buying_company?.tradeName.toLowerCase().includes(query) || supplying_company?.tradeName.toLowerCase().includes(query));
          }).map(({ channel_url }) => channel_url) as string[];

        if (channelUrls?.length) {
          setChannelListQueryParams({
            ...channelListQueryParams,
            channelUrlsFilter: channelUrls!,
          });
        } else {
          setSearchNoResult(true);
        }
      }
    }
  }, [query, contractsData]);

  useEffect(() => {
    if (groupChannels.length) {
      setSearchNoResult(false);
    }
  }, [groupChannels]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: '100%',
        }}
        ref={channelListRef}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="titleLarge">{t('chat:messages')}</Typography>
          <IconButton onClick={() => setNewChannelSlideOpen(true)}>
            <NewChannelIcon />
          </IconButton>
        </Box>
        <SearchInput sx={{ height: 48 }} onChange={(inputQuery) => debounceQuery(inputQuery.toLowerCase())} />
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, overflow: 'auto' }}
          onScroll={(event) => {
            if (event.currentTarget.scrollHeight - event.currentTarget.offsetHeight === event.currentTarget.scrollTop) {
              loadMore();
            }
          }}
        >
          {!!groupChannels.length && !searchNoResult && (
            <>
              {groupChannels.map((channel) => (
                <ChannelListPreview
                  onSelectChannel={() => selectChannel(channel)}
                  channel={channel}
                  key={channel.url}
                />
              ))}
            </>
          )}
          {searchNoResult && (
            <Typography variant="bodyMedium" sx={{ textAlign: 'center', py: 3 }}>
              {t('common:no_result')}
            </Typography>
          )}
        </Box>
      </Box>
      {!!contractsData && (
        <ContactableCompanyList contracts={contractsData.contract} availableChannels={groupChannels} />
      )}
    </>
  );
}
