import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Box, Drawer, Grid2, IconButton, Pagination, Toolbar, Typography, useTheme } from '@mui/material';
import { Availability_Status_Enum, Base_Unit_Type_Enum, Measurement_Unit_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { FormatMeasurementUnit, FormatPackagingVolumePrice, formatPriceByBillingType } from 'kheops-utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePackagingsFromCatalogQuery } from '../../queries/__generated__/packagingsFromCatalog.generated';
import ProductFilterToolbar from '../../common/components/ProductFilterToolbar';
import { PackagingsByCompanyQuery, usePackagingsByCompanyLazyQuery } from '../../queries/__generated__/packagingsByCompany.generated';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import PackagingPricesCard from './PackagingPricesCard';
import { formatPackagingPriceIncludingDescriptor } from '../../common/utils/common.utils';

export interface PackagingPricesDetailsProps {
  companyId: string;
  catalogId: string;
  open: boolean;
  onClose: () => void;
}

export interface PackagingFilters {
  gtin?: string;
  name?: string;
  productFamilies: Product_Sub_Family_Name_Enum[];
}

export type PackagingDetail = PackagingsByCompanyQuery['packaging'][number] & {
  primaryPriceDisplay?: string;
  secondaryPriceDisplay?: string;
  discount?: number;
  discountedPrimaryPriceDisplay?: string;
  discountedSecondaryPriceDisplay?: string;
};

const PAGE_SIZE = 24;

export default function PackagingPricesDetails({ companyId, catalogId, open, onClose }: PackagingPricesDetailsProps): React.JSX.Element {
  const { t } = useTranslation(['contracts', 'packaging', 'products']);
  const priceFormatter = useCurrencyFormat();

  const { data: packagingsFromCatalog } = usePackagingsFromCatalogQuery({
    variables: {
      catalogId,
    },
  });
  const [getPackagings, { data: packagings }] = usePackagingsByCompanyLazyQuery({
    variables: {
      companyId,
      skuPackagingsToIgnore: [],
      availability: { _eq: Availability_Status_Enum.Available },
    },
  });
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<PackagingFilters>({ productFamilies: [] });
  const theme = useTheme();
  const cardListRef = useRef<HTMLElement>(null);

  const drawerZIndex = theme.zIndex.modal + 1; // Here we need to do this trick to make sure that our drawer is opened over the Accept offer dialog.

  useEffect(() => {
    setFilters({ productFamilies: [] });
  }, [open]);

  useEffect(() => {
    if (packagingsFromCatalog?.catalog_packaging) {
      getPackagings({
        variables: {
          companyId,
          skuPackagingsToIgnore: packagingsFromCatalog.catalog_packaging.map(
            ({ packaging }) => packaging!.sku,
          ),
        },
      });
    }
  }, [packagingsFromCatalog]);

  const handleSearch = useCallback((query: string, productFamilies: Product_Sub_Family_Name_Enum[]) => {
    const isGtin = !Number.isNaN(Number(query)) && query.length === 13;

    setFilters({
      ...filters,
      gtin: isGtin ? query : undefined,
      name: !isGtin ? query.toLocaleLowerCase() : undefined,
      productFamilies,
    });
    setPage(0);
  }, [filters]);

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage - 1);
    cardListRef.current?.scrollTo(0, 0);
  }, []);

  const allPackagings = useMemo(() => {
    const discountedPackagings: PackagingDetail[] = packagingsFromCatalog?.catalog_packaging.map(
      ({ discount, packaging }) => ({
        discount,
        ...packaging!,
      }),
    ) || [];

    return discountedPackagings.concat(packagings?.packaging || [])
      .map((packaging) => {
        const adjustedPackaging: PackagingDetail = { ...packaging };

        if (packaging?.base_unit.unit_type === Base_Unit_Type_Enum.Bulk) {
          const volumePrice = FormatPackagingVolumePrice(packaging, packaging.base_unit);
          const formattedPrimaryPrice = priceFormatter.format(volumePrice.value);
          const formattedSecondaryPrice = formatPriceByBillingType(priceFormatter.format(packaging!.price), packaging!.base_unit.billing_type);

          adjustedPackaging.secondaryPriceDisplay = formatPackagingPriceIncludingDescriptor(
            t,
            formattedSecondaryPrice,
            packaging.trade_item_unit_descriptor,
          );
          adjustedPackaging.primaryPriceDisplay = `${formattedPrimaryPrice}/${FormatMeasurementUnit(volumePrice.unit as Measurement_Unit_Enum, 'fr', 1)}`;

          if (packaging.discount) {
            const discountedVolumePrice = volumePrice.value * (1 - packaging.discount);
            const discountedPackagingPrice = packaging!.price * (1 - packaging.discount);

            adjustedPackaging.secondaryPriceDisplay = formattedSecondaryPrice;
            adjustedPackaging.primaryPriceDisplay = formattedPrimaryPrice;
            adjustedPackaging.discountedPrimaryPriceDisplay = `${priceFormatter.format(discountedVolumePrice)}/${FormatMeasurementUnit(volumePrice.unit as Measurement_Unit_Enum, 'fr', 1)}`;
            adjustedPackaging.discountedSecondaryPriceDisplay = formatPackagingPriceIncludingDescriptor(
              t,
              formatPriceByBillingType(priceFormatter.format(discountedPackagingPrice), packaging!.base_unit.billing_type),
              packaging.trade_item_unit_descriptor,
            );
          }
        } else {
          adjustedPackaging.secondaryPriceDisplay = formatPriceByBillingType(priceFormatter.format(packaging!.price), packaging!.base_unit.billing_type);
          adjustedPackaging.primaryPriceDisplay = formatPriceByBillingType(priceFormatter.format((packaging!.price) / packaging!.unit_quantity!), packaging!.base_unit.billing_type);

          if (packaging.discount) {
            const discountedPrice = packaging!.price * (1 - packaging.discount);

            adjustedPackaging.discountedSecondaryPriceDisplay = formatPriceByBillingType(priceFormatter.format(discountedPrice), packaging!.base_unit.billing_type);
            adjustedPackaging.discountedPrimaryPriceDisplay = formatPriceByBillingType(priceFormatter.format((discountedPrice) / packaging!.unit_quantity!), packaging!.base_unit.billing_type);
          }
        }

        return adjustedPackaging;
      });
  }, [packagingsFromCatalog, packagings]);

  const filteredPackagings = useMemo(() => {
    return allPackagings
      .filter((packaging) => {
        return (!filters.gtin || packaging.base_unit.gtin === filters.gtin)
          && (!filters.name || packaging.product.name.toLocaleLowerCase().includes(filters.name))
          && (!filters.productFamilies.length || filters.productFamilies.some((productFamily) => packaging.product.sub_family === productFamily));
      });
  }, [allPackagings, filters]);

  const currentPackagings = useMemo(() => {
    const pageStart = page * PAGE_SIZE;

    return filteredPackagings.slice(pageStart, pageStart + PAGE_SIZE);
  }, [filteredPackagings, page]);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      sx={{
        zIndex: drawerZIndex,
      }}
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
          overflow: 'hidden',
        },
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          backgroundColor: 'background.paper',
          py: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          minHeight: 40,
        }}
      >
        <Typography variant="displayMedium">
          {t('contracts:preferential_prices')}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            backgroundColor: 'greys.primary',
            color: 'text.primary',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Box
        sx={{
          width: {
            xs: '100%',
            sm: 640,
            lg: 900,
          },
          mx: 'auto',
          px: {
            xs: 2,
            sm: 0,
          },
          my: 3,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
        }}
      >
        <ProductFilterToolbar
          products={allPackagings.map(({ product }) => product)}
          onChange={handleSearch}
          sx={{
            '& > *': {
              flex: 1,
            },
          }}
        />
        <Box
          ref={cardListRef}
          sx={{
            overflow: 'auto',
          }}
        >
          <Grid2
            container
            columnSpacing={2}
            rowSpacing={1}
          >
            {currentPackagings.map((packaging) => (
              <PackagingPricesCard packaging={packaging} />
            ))}
          </Grid2>
          <Pagination
            color="primary"
            sx={{
              mt: 2,
              '& .MuiPagination-ul': {
                justifyContent: 'center',
              },
            }}
            page={page + 1}
            count={Math.ceil(filteredPackagings.length / PAGE_SIZE)}
            onChange={(_, newPage) => handlePageChange(newPage)}
          />
        </Box>
      </Box>
    </Drawer>
  );
}
