import { Box, Button, Chip, Typography, useMediaQuery, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { PackagingFamilyTypeIntl } from '../../../common/i18n/product-family.translation';
import { RoutePaths } from '../../../routes/AppRoutes';
import useOpenable from '../../../hooks/useOpenable';
import ProductLabels from './ProductLabels';
import ProductVatRate from './ProductVatRate';
import ProductIngredients from './ProductIngredients';
import ProductAllergens from './ProductAllergens';
import ProductNutritionFacts from './ProductNutritionFacts';
import ProductInfoDialog from './ProductInfoDialog';
import { ProductFragment } from '../../products';
import DeleteProductConfirmDialog from '../DeleteProductConfirmDialog';
import { commonSnackbarPropsAtom } from '../../../common/state/state';
import ProductUtils from '../../common/products.utils';
import { useDeleteProductMutation } from '../../../mutations/__generated__/deleteProduct.generated';
import { PackagingsByCompanyDocument } from '../../../queries/__generated__/packagingsByCompany.generated';
import BackButton from '../../../common/components/BackButton';
import ProductDescription from './ProductDescription';
import ShelfLifeDurationDisplay from './ShelfLifeDurationDisplay';

interface ProductInfoProps {
  product: ProductFragment;
}

export default function ProductInfo({ product }: ProductInfoProps): React.JSX.Element {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'products', 'product-family']);
  const [deleteProduct, deleteProductOutput] = useDeleteProductMutation({ refetchQueries: ['ProductVersionById', PackagingsByCompanyDocument] });
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const { open, close, isOpen } = useOpenable();
  const { open: openDeleteProductConfirm, close: closeDeleteProductConfirm, isOpen: isDeleteProductConfirmOpen } = useOpenable();

  const handleDeleteConfirm = useCallback((confirm: boolean): void => {
    if (confirm) {
      const productInsertInput = ProductUtils.convertProductFragmentIntoInsertInput(product);

      productInsertInput.deleted_at = new Date().toISOString();

      deleteProduct({
        variables: {
          product: productInsertInput,
          sku: productInsertInput.sku!,
          company_id: productInsertInput.company_id!,
        },
        update: (cache) => {
          cache.evict({ fieldName: 'product' });
        },
      });
    } else {
      closeDeleteProductConfirm();
    }
  }, []);

  useEffect((): void => {
    if (deleteProductOutput.data?.insert_product) {
      setCommonSnackbarProps({
        label: t('common:update_successful'),
        snackbarProps: {
          open: true,
        },
      });

      closeDeleteProductConfirm();
      navigate(RoutePaths.COMPANY_PRODUCTS);
    }
  }, [deleteProductOutput]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
            lg: 'column',
            xl: 'row',
          },
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 1,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Box sx={{ display: 'flex', alignItems: 'top', gap: 1, mb: 1 }}>
            <BackButton
              to={RoutePaths.COMPANY_PRODUCTS}
              shouldCheckHistory={false}
              compact
              color="secondary.light"
              sx={{
                display: {
                  xs: 'none',
                  lg: 'inline-flex',
                },
                height: 'fit-content',
              }}
            />
            <Typography variant="titleLarge" sx={{ alignSelf: 'center' }}>
              {product.name}
            </Typography>
          </Box>
          <Chip
            icon={<ShoppingBasketOutlinedIcon />}
            color="marketing1"
            size="small"
            sx={{
              fontSize: '1.125rem',
              fontWeight: 400,
              height: 28,
            }}
            label={t(`product-family:${PackagingFamilyTypeIntl[product.sub_family as Product_Sub_Family_Name_Enum]}`)}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'row',
              sm: 'column',
              lg: 'row',
              xl: 'column',
            },
            gap: 1,
            flexShrink: 0,
            mt: {
              xs: 1,
              sm: 0,
              lg: 1,
              xl: 0,
            },
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<EditIcon fontSize="small" />}
            component={Link}
            to={`${RoutePaths.PRODUCT_ADD_INFO}?productId=${product.id}`}
          >
            {t('common:modify')}
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteOutlineIcon fontSize="small" />}
            onClick={openDeleteProductConfirm}
          >
            {t('common:delete')}
          </Button>
        </Box>
      </Box>
      {!!product.labels.length && (
        <ProductLabels labels={product.labels} short={isDownMd} />
      )}
      {
        !isDownMd
          ? (
            <>
              {product.description && <ProductDescription description={product.description} />}
              <ProductVatRate
                vatRate={product.vat_rate!}
                sx={{
                  display: {
                    xs: 'none',
                    md: 'flex',
                  },
                }}
              />
              <ShelfLifeDurationDisplay
                titleVariant="titleSmall"
                duration={product.shelf_life_duration}
                durationType={product.shelf_life_duration_type}
              />
              {product.ingredients && <ProductIngredients ingredients={product.ingredients} />}
              {product.allergens && <ProductAllergens allergens={product.allergens} />}
              <ProductNutritionFacts product={product} />
            </>
          ) : (
            <Button
              variant="text"
              color="secondary"
              sx={{
                p: 0,
                alignSelf: 'start',
                textDecoration: 'underline',
                '&:hover': {
                  textDecoration: 'underline',
                  backgroundColor: 'transparent',
                },
              }}
              disableRipple
              onClick={open}
            >
              + {t('products:see_product_details')}
            </Button>
          )
      }
      <ProductInfoDialog open={isOpen} onClose={close} product={product} />
      {isDeleteProductConfirmOpen && (
        <DeleteProductConfirmDialog open={isDeleteProductConfirmOpen} onClose={handleDeleteConfirm} product={product} />
      )}
    </Box>
  );
}
