import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Maybe, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import ConfirmDialog from '../../common/components/ConfirmDialog';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import { RawPhoto } from '../../common/common';

interface DeleteBaseUnitDialogProps {
  open: boolean;
  onClose: (confirm: boolean) => void;
  subFamily: Product_Sub_Family_Name_Enum;
  photo?: Maybe<RawPhoto>;
}

export default function DeleteBaseUnitDialog({ open, onClose, subFamily, photo }: DeleteBaseUnitDialogProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'products']);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={t('products:delete_the_base_unit')}
      confirmLabel={t('common:delete')}
      cancelLabel={t('common:cancel')}
      confirmColor="error"
      waitForClosing
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="bodyMedium" color="secondary">
          {t('products:you_are_about_to_delete_a_base_unit')}
        </Typography>
        <SizedImage
          src={FileUtils.BuildPackagingImageUrl({
            photo,
            type: subFamily,
            size: { height: 256, fit: 'contain' },
          })}
          borderRadius={0}
          alt={t('products:csu')}
          width={200}
          height={200}
          sx={{ mx: 'auto', mt: 1 }}
        />
      </Box>
    </ConfirmDialog>
  );
}
