import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { ContractOfferInfo, showChannelMembersAtom } from '../state';
import OfferData from './OfferData';
import { currentContextAtom } from '../../state';
import CommissionInfo from '../../common/components/CommissionInfo';

export interface OfferInfoHeaderProps {
  contract: ContractOfferInfo;
}

const RECEIVED_OFFER_WORDINGS = {
  [Business_Profile_Enum.Buyer]: {
    title: 'contracts:supplier_made_you_an_offer',
    description: 'contracts:supplier_made_you_an_offer_description',
  },
  [Business_Profile_Enum.Supplier]: {
    title: 'contracts:buyer_made_you_an_offer',
    description: 'contracts:buyer_made_your_an_offer_description',
  },
};

const SENT_OFFER_WORDINGS = {
  [Business_Profile_Enum.Buyer]: {
    title: 'contracts:made_an_offer_to_supplier',
    description: 'contracts:you_made_an_offer_to_supplier_description',
  },
  [Business_Profile_Enum.Supplier]: {
    title: 'contracts:made_an_offer_to_buyer',
    description: 'contracts:you_made_an_offer_to_buyer_description',
  },
};

export default function OfferInfoHeader({ contract }: OfferInfoHeaderProps): React.JSX.Element {
  const { t } = useTranslation(['contracts']);
  const { realm, commissionRate } = useAtomValue(currentContextAtom);
  const showChannelMembers = useAtomValue(showChannelMembersAtom);
  const offerByUs = contract.proposer_business_profile === realm;
  const wordingsMap = offerByUs ? SENT_OFFER_WORDINGS : RECEIVED_OFFER_WORDINGS;
  const partner = realm === Business_Profile_Enum.Buyer ? contract.supplying_company.tradeName : contract.buying_company.tradeName;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          lg: !offerByUs || showChannelMembers ? 'column' : 'row',
        },
        gap: {
          xs: 2,
          lg: 3,
        },
        mb: {
          xs: 2,
          lg: 3,
        },
      }}
    >
      <Box>
        <Typography variant="titleMedium">
          {t(wordingsMap[realm].title, { partner })}
        </Typography>
        <Typography
          variant="bodyMedium"
          color="secondary"
          sx={{
            mt: 0.5,
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}
        >
          {t(wordingsMap[realm].description)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 1 }}>
        <OfferData
          contractId={contract.id}
          offerInfo={{
            discount: contract.discount,
            minimum_order_value: contract.minimum_order_value,
            catalog_id: contract.catalog_id,
            catalog_name: contract.catalog?.name,
            supplyingCompanyId: contract.supplyingCompanyId,
          }}
          editable={offerByUs}
          negotiable={!offerByUs}
          sx={{
            width: !offerByUs || showChannelMembers ? 'calc(100% - 32px)' : 'initial',
          }}
        />
        {realm === Business_Profile_Enum.Supplier && <CommissionInfo commission={commissionRate as number} size="small" />}
      </Box>
    </Box>
  );
}
