import { Grid2 } from '@mui/material';
import { useLocation, useParams } from 'react-router';
import { ScopeProvider } from 'jotai-scope';
import OrderInformation from './OrderInformation';
import BackBar from '../../common/components/BackBar';
import { RoutePaths } from '../../routes/AppRoutes';
import { PageWrapper } from '../../common/components/PageWrapper';
import OrderProvider from './OrderProvider';
import OrderPackagings from './OrderPackagings';
import OrderStatus from './OrderStatus';
import OrderPackagingWeightUpdate from './OrderPackagingWeightUpdate';
import { currentBasketItemsAtom } from '../../basket/state/state';

export default function OrderTrackingPage(): React.JSX.Element {
  const { orderReferenceId } = useParams();
  const location = useLocation();

  return (
    <>
      <BackBar to={RoutePaths.ORDER_LIST} />
      <PageWrapper>
        {orderReferenceId && (
          <ScopeProvider atoms={[currentBasketItemsAtom]} key={location.key}>
            <OrderProvider orderReferenceId={orderReferenceId}>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, lg: 4, xl: 3 }}>
                  <OrderInformation />
                </Grid2>
                <Grid2
                  size={{ xs: 12, lg: 8, xl: 9 }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <OrderStatus />
                  <OrderPackagingWeightUpdate />
                  <OrderPackagings />
                </Grid2>
              </Grid2>
            </OrderProvider>
          </ScopeProvider>
        )}
      </PageWrapper>
    </>
  );
}
