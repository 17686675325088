import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAtomValue, useSetAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { Business_Profile_Enum } from 'kheops-graphql';
import { Chat_Message_Custom_Type } from 'kheops-utils';
import { ContractOfferInfo } from '../state';
import ChannelCompanyImage from '../ChannelCompanyImage';
import SearchInput from '../../common/components/SearchInput';
import KheopsAvatar from '../../common/components/KheopsAvatar';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import { currentContextAtom, userAtom } from '../../state';

export interface AddMemberDialogProps {
  channel: GroupChannel;
  contract: ContractOfferInfo;
  isOpen: boolean;
  onClose: () => void;
}

interface AddMembersFormValues {
  members: Array<{
    userId: string,
    isInChannel: boolean,
    disabled: boolean;
    name: string;
  }>;
}

export default function AddMemberDialog({ isOpen, onClose, contract, channel }: AddMemberDialogProps): React.JSX.Element {
  const { t } = useTranslation(['chat', 'common']);
  const { realm } = useAtomValue(currentContextAtom);
  const currentUser = useAtomValue(userAtom);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  const company = realm === Business_Profile_Enum.Buyer ? contract.buying_company : contract.supplying_company;

  const { control, formState } = useForm<AddMembersFormValues>({
    defaultValues: {
      members: company.role_assignments.map(({ user }) => {
        const isInChannel = channel.members.some(({ userId }) => userId === user.id);

        return {
          userId: user.id,
          isInChannel,
          disabled: isInChannel,
          name: `${user.first_name} ${user.last_name}`,
        };
      }),
    },
  });
  const { fields, update } = useFieldArray({
    control,
    name: 'members',
  });

  const addMembers = async (): Promise<void> => {
    setLoading(true);

    const newMembers = fields.filter((member) => member.isInChannel && !member.disabled);

    await channel.inviteWithUserIds(newMembers.map(({ userId }) => userId));

    await Promise.all(newMembers.map((member): Promise<void> => {
      const handler = channel.sendUserMessage({
        customType: Chat_Message_Custom_Type.INVITED,
        message: t('chat:someone_has_invited_someone_to_the_conversation', { name: `${currentUser.first_name} ${currentUser.last_name}`, targetName: member.name }),
        data: member.name,
      });

      return new Promise((resolve) => {
        handler.onSucceeded(() => {
          resolve();
        });
      });
    }));

    setCommonSnackbarProps({
      label: t('common:update_successful'),
      snackbarProps: {
        open: true,
      },
    });

    setLoading(false);
    onClose();
  };

  const filteredRoleAssignments = useMemo(() => {
    const loweredCaseQuery = query.toLocaleLowerCase();

    return company.role_assignments
      .map(({ user }, index) => ({ user, index })) // keeping base array index let us synchronize the list of role assignments and the list of fields
      .filter(({ user }) => {
        return user.first_name?.toLocaleLowerCase().includes(loweredCaseQuery)
          || user.last_name?.toLocaleLowerCase().includes(loweredCaseQuery);
      });
  }, [query]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          minWidth: {
            xs: 'calc(100vw - 64px)',
            md: 640,
          },
        },
      }}
    >
      <DialogTitle sx={{ mt: 0, pt: 3, pb: 2 }}>
        {t('chat:add_members_to_conversation')}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Divider sx={{ mb: 2 }} />
        <SearchInput
          value={query}
          onChange={(inputQuery) => setQuery(inputQuery)}
          fullWidth
          sx={{ my: 1 }}
        />
        <Box sx={{ overflow: 'auto' }}>
          <Box sx={{ display: 'flex', my: 2, gap: 1 }}>
            <ChannelCompanyImage
              company={{
                businessProfile: realm,
                photo: company.photos[0]?.photo,
                brand: 'brand' in company ? company.brand : undefined,
              }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="bodyMedium">
                {company.tradeName}
              </Typography>
              <Typography variant="bodySmall" color="secondary">
                {t('chat:N_member', { count: company.role_assignments.length })}
              </Typography>
            </Box>
          </Box>
          <FormGroup sx={{ display: 'flex', flexDirection: 'column', width: 'fit-content', gap: 2 }}>
            {filteredRoleAssignments.map(({ user, index }) => (
              <FormControlLabel
                key={user.id}
                label={(
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 1 }}>
                    <KheopsAvatar size={40} userAvatars={user} />
                    <Typography variant="bodyMedium">
                      {fields[index].name}
                    </Typography>
                  </Box>
                )}
                control={(
                  <Checkbox
                    checked={fields[index].isInChannel}
                    onChange={(_, checked) => {
                      update(
                        index,
                        {
                          disabled: false,
                          name: fields[index].name,
                          userId: fields[index].userId,
                          isInChannel: checked,
                        },
                      );
                    }}
                    disabled={fields[index].disabled}
                  />
                )}
                sx={{ p: 1 }}
              />
            ))}
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pt: 2, mt: 0.5 }}>
        <Button onClick={onClose} color="secondary">
          {t('common:cancel')}
        </Button>
        <LoadingButton
          onClick={addMembers}
          color="primary"
          variant="contained"
          loading={loading}
          disabled={!formState.isDirty}
        >
          {t('chat:add_members')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
