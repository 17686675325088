import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, IconButton } from '@mui/material';
import { useSetAtom } from 'jotai';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { Order_Status_Enum } from 'kheops-graphql';
import { editedOrderItemsAtom } from '../../orders/state/state';
import QuantitySelector from './quantitySelector/QuantitySelector';
import VersionedValue from './VersionedValue';
import useOrderItem from '../../hooks/useOrderItem';

interface OrderItemEditBoxProps {
  packagingId: string;
  orderStatus: Order_Status_Enum;
  index: number;
  canEditQuantity?: boolean;
  shouldEditBeEnabledByDefault?: boolean;
}

export default function OrderItemEditBox({ packagingId, orderStatus, index, canEditQuantity, shouldEditBeEnabledByDefault = false }: OrderItemEditBoxProps): React.JSX.Element {
  const { quantity, defaultQuantity, previousQuantity } = useOrderItem(packagingId) || {};
  const editItem = useSetAtom(editedOrderItemsAtom);
  const [editMode, setEditMode] = useState(shouldEditBeEnabledByDefault);

  // defaultquantity is set as dependency to trigger this useEffect when order is saved but the order status doesnt change.
  useEffect(() => {
    setEditMode(shouldEditBeEnabledByDefault);
  }, [orderStatus, shouldEditBeEnabledByDefault, defaultQuantity]);

  const handleEditModeClick = useCallback((event?: MouseEvent): void => {
    if (event) {
      event.stopPropagation();
    }
    setEditMode(true);
  }, []);

  const handleOnCancelClick = (event: MouseEvent): void => {
    event.stopPropagation();

    if (quantity !== defaultQuantity) {
      editItem({
        id: packagingId,
        quantity: defaultQuantity || 0,
      });
    }

    setEditMode(false);
  };

  const handleChange = (newQuantity: number): void => {
    editItem({
      id: packagingId,
      quantity: newQuantity,
    });
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!editMode ? (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
        >
          {canEditQuantity && (
            <IconButton onClick={handleEditModeClick} color="secondary" data-testid={`edit-quantity-button-${index}`}>
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
          )}
          {quantity !== undefined && (
          <VersionedValue
            currentValue={quantity}
            previousValue={quantity !== defaultQuantity ? defaultQuantity : previousQuantity}
            shouldHighlightDifference
            color="text.main"
            variant="bodyMedium"
          />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <QuantitySelector
            quantity={quantity || 0}
            onQuantityChange={handleChange}
            defaultValue={defaultQuantity}
            index={index}
          />
          <IconButton size="small" onClick={handleOnCancelClick}>
            <CloseIcon sx={{ width: 16 }} />
          </IconButton>
        </Box>
      )}
    </>
  );
}
