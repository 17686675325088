import { Box, Button, Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useOpenable from '../../hooks/useOpenable';
import ProductFamiliesSettings from '../settingsDialog/ProductFamiliesSettings';

export default function EmptyDepartmentsPlaceholder(): React.JSX.Element {
  const { t } = useTranslation(['settings']);
  const { open, close, isOpen } = useOpenable();

  return (
    <Box
      sx={{
        p: 2,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 4,
        textAlign: 'center',
      }}
    >
      <Typography variant="titleMedium" sx={{ mb: 1 }}>
        {t('settings:no_departements_linked')}
      </Typography>
      <Typography variant="bodyMedium" color="secondary">
        {t('settings:no_departements_linked_description')}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        sx={{ mt: 2 }}
        onClick={open}
      >
        {t('settings:set_my_departments')}
      </Button>
      <Dialog
        open={isOpen}
        onClose={(): void => { /* noop */ }}
        sx={{
          mx: {
            xs: 2,
            sm: 0,
          },
          '& .MuiPaper-root.MuiDialog-paper': {
            minWidth: {
              xs: '100%',
              sm: 640,
            },
          },
        }}
      >
        <ProductFamiliesSettings next={close} standAlone />
      </Dialog>
    </Box>
  );
}
