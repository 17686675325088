import { Box, Button, Checkbox, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAtomValue } from 'jotai';
import KheopsAvatar from '../../../common/components/KheopsAvatar';
import { arrayToggle } from '../../../common/utils/common.utils';
import { currentContextAtom } from '../../../state';
import { ContactsDraft } from '../state';
import { CompanyRoleAssignment } from '../../../common/models/user.models';
import { BaseFormProps } from '../FormDrawer';

export interface ContactsFormProps extends BaseFormProps {
  contacts: CompanyRoleAssignment[];
  onSubmit: (contactIds: string[]) => void;
  description: string;
}

export default function ContactsForm({ contacts, description, onSubmit, onChange, onCancel }: ContactsFormProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'settings']);
  const { companyRoleAssignments } = useAtomValue(currentContextAtom);
  const { handleSubmit, formState: { isDirty }, setValue, watch } = useForm<ContactsDraft>({
    defaultValues: {
      contactIds: contacts.map(({ id }) => id),
    },
  });
  const contactIds = watch('contactIds');
  const values = watch();

  const handleContactIdsChange = (id: string): void => {
    const newContactIds = [...contactIds];

    arrayToggle(newContactIds, id);

    setValue('contactIds', newContactIds, { shouldDirty: true });
  };

  useEffect((): void => {
    onChange(isDirty);
  }, [values, isDirty]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit((data) => onSubmit(data.contactIds))}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="titleMedium">
          {t('settings:contact_list')}
        </Typography>
        <Typography variant="bodyMedium" color="secondary">
          {t(description)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {[...companyRoleAssignments]
          .sort((roleA, roleB) => roleA.user.first_name.localeCompare(roleB.user.first_name))
          .map(({ id, user: { first_name, last_name, auth_provider_avatar_url, default_avatar } }) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 1 }}>
              <Checkbox
                key={id}
                checked={contactIds.includes(id)}
                onChange={() => handleContactIdsChange(id)}
                disableRipple
                sx={{ p: 0 }}
              />
              <KheopsAvatar userAvatars={{ auth_provider_avatar_url, default_avatar }} size={40} sx={{ borderRadius: 50 }} />
              <Typography variant="bodyMedium">
                {first_name} {last_name}
              </Typography>
            </Box>
          ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, position: 'sticky', bottom: -24, backgroundColor: 'white', py: 1 }}>
        <Button variant="text" color="secondary" onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!isDirty}>
          {t('common:validate')}
        </Button>
      </Box>
    </Box>
  );
}
