import { Divider, FormHelperText, Grid2, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';
import { useTranslation } from 'react-i18next';
import { ComputePackagingPriceFromVolumePrice, FormatPackagingVolumePrice, formatPriceByBillingType, round } from 'kheops-utils';
import { Base_Unit_Type_Enum, Billing_Type_Enum } from 'kheops-graphql';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { CompanyPackaging } from '../state';
import { DraftPackagingsPrices } from './state';
import CompanyPackagingCard from '../companyPackagingCard';

export interface CatalogProductsFormRowProps {
  packaging: CompanyPackaging;
}

export default function PackagingsPricesFormRow({ packaging }: CatalogProductsFormRowProps): React.JSX.Element {
  const productIsBilledByWeight = packaging.base_unit.billing_type === Billing_Type_Enum.WeightDependent;
  const isBulk = packaging.base_unit.unit_type === Base_Unit_Type_Enum.Bulk;
  const { t } = useTranslation(['products', 'validation', 'price']);
  const currencyFormat = useCurrencyFormat();
  const { control, setValue, watch } = useFormContext<DraftPackagingsPrices>();
  const formPackagingPrice = watch(`prices.${packaging.sku}.packagingPrice`);
  const formBaseUnitPrice = watch(`prices.${packaging.sku}.packagingUnitPrice`);

  const volumePrice = useMemo(() => {
    if (isBulk || productIsBilledByWeight) {
      return FormatPackagingVolumePrice(packaging, packaging.base_unit);
    }
  }, [packaging]);

  const maxPrice = useMemo(() => {
    if (packaging.base_unit.suggested_retail_price) {
      return round(packaging.base_unit.suggested_retail_price / (1 + packaging.product.vat_rate!), 2);
    }

    return Infinity;
  }, [packaging]);

  useEffect(() => {
    const price = Number.isNaN(formBaseUnitPrice) ? 0 : formBaseUnitPrice;
    const packagingPrice = isBulk || productIsBilledByWeight
      ? round(ComputePackagingPriceFromVolumePrice(
        packaging,
        {
          ...packaging.base_unit,
        },
        price,
      ))
      : round(price * (packaging.unit_quantity || packaging.net_content)!);

    setValue(`prices.${packaging.sku}.packagingPrice`, round(packagingPrice), { shouldDirty: true });
  }, [formBaseUnitPrice]);

  return (
    <Grid2 container size={{ xs: 12 }} columnSpacing={1}>
      <Grid2
        size={{ xs: 12, lg: 6 }}
        sx={{
          display: 'flex',
          gap: 1,
          py: {
            xs: 2,
            lg: 0,
          },
        }}
      >
        <CompanyPackagingCard packaging={packaging} />
      </Grid2>
      <Grid2
        container
        size={{ xs: 12, lg: 6 }}
        columnSpacing={1}
        sx={{
          py: 1,
          display: {
            xs: 'flex',
            lg: 'none',
          },
        }}
      >
        <Grid2 size={{ xs: 6 }}>
          <Typography variant="bodySmall" color="secondary">
            {t('price:packaging_price_without_tax')}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Typography variant="bodySmall" color="secondary">
            {t('price:catalog_price_without_tax')}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2
        container
        size={{ xs: 12, lg: 6 }}
        columnSpacing={1}
        sx={{
          alignItems: 'baseline',
          py: {
            xs: 1,
            lg: 0,
          },
        }}
      >
        <Grid2 size={{ xs: 6 }} sx={{ margin: { xs: 'auto', lg: 'unset' } }}>
          {formatPriceByBillingType(
            currencyFormat.format(formPackagingPrice),
            packaging.base_unit.billing_type,
          )}
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Controller
            name={`prices.${packaging.sku}.packagingUnitPrice`}
            control={control}
            rules={{
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
              max: { value: maxPrice, message: t('validation:this_value_should_be_lower_than_srp', { maxValue: currencyFormat.format(maxPrice) }) },
              required: true,
            }}
            render={({ field, fieldState }) => (
              <>
                <OutlinedInput
                  {...field}
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    field.onChange(event);
                  }}
                  error={!!fieldState.error}
                  inputProps={{
                    min: '0',
                    max: maxPrice,
                    step: '.01',
                  }}
                  type="number"
                  endAdornment={(
                    <InputAdornment position="start">
                      {volumePrice
                        ? `€/${volumePrice.unit}`
                        : <EuroIcon sx={{ fontSize: '1rem' }} />}
                    </InputAdornment>
                    )}
                  sx={{
                    '& input[type=number]::-webkit-outer-spin-button': {
                      display: 'none',
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                      display: 'none',
                    },
                  }}
                />
                {fieldState.error && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid2>
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <Divider sx={{ my: 2 }} />
      </Grid2>
    </Grid2>
  );
}
