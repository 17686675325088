import { Box, Button } from '@mui/material';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { BusinessProfile, Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import { CreateGroupChannelMutation, useCreateGroupChannelMutation } from '../../mutations/__generated__/createGroupChannel.generated';
import { ContractsByCompanyIdDocument } from '../../queries/__generated__/contractsByCompanyId.generated';
import { CompanyInfo } from './CompanyInfoCard';
import ContractRequestButton from '../ContractRequestButton';
import { RoutePaths } from '../../routes/AppRoutes';
import useJoinDefaultChannel from '../../chats/hooks/useJoinDefaultChannel';
import { currentContextAtom } from '../../state';
import { ContractPreview } from '../../hooks/useContracts';
import { ContractsAsBuyerDocument } from '../../queries/__generated__/contractsAsBuyer.generated';
import { ContractsAsSupplierDocument } from '../../queries/__generated__/contractsAsSupplier.generated';
import useOpenable from '../../hooks/useOpenable';
import CompanyContactDialog from './CompanyContactDialog';
import BuyerInfoDialog from '../buyer/dialog/BuyerInfoDialog';
import ExportProductsButton from './ExportProductsButton';

export interface ContactBoxProps {
  company: CompanyInfo;
  contract?: ContractPreview;
}

export default function ContactBox({ company, contract }: ContactBoxProps): React.JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'contracts']);
  const { realm, companyId } = useAtomValue(currentContextAtom);
  const [createGroupChannel] = useCreateGroupChannelMutation({ refetchQueries: [ContractsByCompanyIdDocument, ContractsAsBuyerDocument, ContractsAsSupplierDocument] });
  const [joinDefaultChannel] = useJoinDefaultChannel();
  const [loading, setLoading] = useState(false);

  const { isOpen: isContactDialogOpen, open: openContactDialog, close: closeContactDialog } = useOpenable();
  const { isOpen: isBuyerInfoDialogOpen, open: openBuyerInfoDialog, close: closeBuyerInfoDialog } = useOpenable();
  const isContractSigned = contract?.status === Contract_Status_Enum.Signed;

  const handleSeeDiscussionClick = useCallback(async (): Promise<void> => {
    let joined = false;
    let data: CreateGroupChannelMutation | null | undefined;

    setLoading(true);

    if (contract?.channel_url) {
      joined = await joinDefaultChannel({ channelUrl: contract.channel_url, contractId: contract.id });
    } else {
      let buyingCompanyId: string;
      let supplyingCompanyId: string;

      if (realm === Business_Profile_Enum.Buyer) {
        buyingCompanyId = companyId;
        supplyingCompanyId = company.id;
      } else {
        buyingCompanyId = company.id;
        supplyingCompanyId = companyId;
      }

      data = (await createGroupChannel({ variables: { buyingCompanyId, supplyingCompanyId, fromBusinessProfile: realm as unknown as BusinessProfile } })).data;
    }

    if (data?.createGroupChannel?.channelUrl) {
      navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${data.createGroupChannel.channelUrl}`);
    } else if (joined) {
      navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${contract!.channel_url}`);
    }

    setLoading(false);
  }, [contract]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column-reverse',
          sm: 'row',
        },
        gap: {
          xs: 1,
        },
      }}
    >
      {!!contract && (
        <>
          {isContractSigned && company.business_profile === Business_Profile_Enum.Buyer && (
            <>
              <Button
                startIcon={<InfoOutlinedIcon />}
                onClick={openBuyerInfoDialog}
                variant="outlined"
              >
                {t('common:more_information')}
              </Button>
              <BuyerInfoDialog close={closeBuyerInfoDialog} isOpen={isBuyerInfoDialogOpen} companyId={company.id} />
            </>
          )}
          {company.business_profile === Business_Profile_Enum.Supplier && (
            <>
              <Button
                startIcon={<PersonOutlineIcon />}
                onClick={() => openContactDialog()}
                variant="outlined"
                sx={{ flexGrow: { sm: 0.5, md: 'unset' } }}
              >
                {t('common:contacts')}
              </Button>
              {contract.status === Contract_Status_Enum.Signed && (<ExportProductsButton supplierCompany={company} />)}
              <CompanyContactDialog
                company={company}
                isOpen={isContactDialogOpen}
                onClose={closeContactDialog}
                contractRequestButton={
                  isContractSigned
                    ? undefined
                    : (
                      <LoadingButton
                        variant="contained"
                        onClick={() => handleSeeDiscussionClick()}
                        loading={loading}
                        startIcon={<AddBusinessOutlinedIcon />}
                      >
                        {t(realm === Business_Profile_Enum.Buyer ? 'contracts:trade_with_this_supplier' : 'contracts:trade_with_this_buyer')}
                      </LoadingButton>
                    )
                }
              />
            </>
          )}
          {!isContractSigned && (
            <LoadingButton
              variant="contained"
              onClick={() => handleSeeDiscussionClick()}
              loading={loading}
              startIcon={<AddBusinessOutlinedIcon />}
            >
              {t(realm === Business_Profile_Enum.Buyer ? 'contracts:trade_with_this_supplier' : 'contracts:trade_with_this_buyer')}
            </LoadingButton>
          )}
        </>
      )}
      {isContractSigned && (
        <LoadingButton
          variant="outlined"
          onClick={() => handleSeeDiscussionClick()}
          loading={loading}
          startIcon={<MessageOutlinedIcon />}
        >
          {t('contracts:see_discussion')}
        </LoadingButton>
      )}
      {!contract && realm === Business_Profile_Enum.Buyer && (
        <ContractRequestButton
          companyId={company.id}
          toolTipWording=""
          buttonWording={t('contracts:contact')}
          buttonProps={{
            startIcon: <AddBusinessOutlinedIcon sx={{ ml: 0.5 }} />,
            sx: {
              minWidth: {
                xs: '100%',
                sm: 'unset',
              },
            },
          }}
          whenDisableTooltipHover
        />
      )}
    </Box>
  );
}
