import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BillingIcon from '../../assets/icons/billing.svg?react';
import { RoutePaths } from '../../routes/AppRoutes';
import TopBar from '../../common/components/TopBar';
import BackButton from '../../common/components/BackButton';
import CompanyInfoSection from '../CompanyInfoSection';
import InvoicingAddressInfo from './address/InvoicingAddressInfo';
import ContactsInfo from './contacts/ContactsInfo';

export default function BuyerBillingInfoPage(): React.JSX.Element {
  const { t } = useTranslation(['common']);

  return (
    <>
      <TopBar
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          px: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        <BackButton
          to={RoutePaths.NAVIGATION_MAIN}
          shouldCheckHistory={false}
          compact
          sx={{
            p: 1,
            width: 40,
            height: 40,
            '& .MuiSvgIcon-root': {
              width: 24,
              height: 24,
            },
          }}
        />
        <BillingIcon height={32} width={32} />
        <Typography variant="titleLarge" sx={{ ml: 1 }}>
          {t('common:billing')}
        </Typography>
      </TopBar>
      <Card
        sx={{
          height: {
            lg: 'calc(100% - 48px)',
          },
          mt: {
            xs: 8,
            lg: 0,
          },
          px: {
            xs: 0,
            md: 3,
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: 3,
          overflow: 'auto',
          backgroundColor: {
            xs: 'transparent',
            lg: 'background.paper',
          },
        }}
      >
        <Box
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
            alignItems: 'center',
            gap: 1,
          }}
        >
          <BillingIcon height={32} width={32} />
          <Typography variant="titleLarge">
            {t('common:billing')}
          </Typography>
        </Box>
        <CompanyInfoSection icon="📍" title={t('common:address')}>
          <InvoicingAddressInfo />
        </CompanyInfoSection>
        <CompanyInfoSection icon="👤" title={t('common:contacts')}>
          <ContactsInfo type="INVOICING" />
        </CompanyInfoSection>
      </Card>
    </>
  );
}
