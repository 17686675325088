import { Accordion, AccordionDetails, AccordionSummary, accordionClasses, accordionSummaryClasses, Box, Button, ButtonProps, Popover, Typography, SxProps, Theme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useAtom } from 'jotai';
import { useState } from 'react';
import useOpenable from '../../hooks/useOpenable';
import { currentExpandedAccordionAtom, FiltersName } from '../state/state';

export type FilterButtonMode = 'POPOVER' | 'ACCORDION';
export type FilterButtonVariant = 'outlined' | 'text';

interface FilterButtonProps {
  buttonProps: ButtonProps;
  label: string;
  activeItemCount: number;
  content: React.JSX.Element;
  variant?: FilterButtonVariant;
  mode?: FilterButtonMode;
  name?: FiltersName;
  onClose?: () => void;
}

const VariantSxMap: {[variant in FilterButtonVariant]: SxProps<Theme>} = {
  text: {
    borderRadius: 2,
    '&.KheopsFilterButton-active': {
      color: 'primary.main',
    },
  },
  outlined: {
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: 4,
    px: 2,
  },
};

export default function FilterButton({ buttonProps, label, activeItemCount, content, variant, mode, name, onClose }: FilterButtonProps): React.JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { isOpen, open, close } = useOpenable();
  const [currentExpandedAccordion, setCurrentExpandedAccordion] = useAtom(currentExpandedAccordionAtom);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    open();
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
    close();

    if (onClose) {
      onClose();
    }
  };

  const handleAccordionChange = (): void => {
    setCurrentExpandedAccordion(currentExpandedAccordion === name ? null : name!);
  };

  if (mode === 'ACCORDION') {
    return (
      <Accordion
        expanded={currentExpandedAccordion === name}
        onChange={handleAccordionChange}
        disableGutters
        elevation={0}
        square
        sx={{
          borderRadius: 3,
          my: 2,
          '&:before': {
            display: 'none',
          },
          [`&.${accordionClasses.root}.${accordionClasses.expanded}`]: {
            my: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          sx={{
            [`& .${accordionSummaryClasses.content}`]: {
              alignItems: 'center',
            },
          }}
        >
          {buttonProps.startIcon}
          <Typography variant="titleMedium" sx={{ ml: 0.5 }}>
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {content}
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <>
      <Button
        className={isOpen ? 'KheopsFilterButton-active' : undefined}
        onClick={handleFilterClick}
        endIcon={
          isOpen
            ? <KeyboardArrowUpIcon />
            : <KeyboardArrowDownIcon />
        }
        color="secondary"
        sx={{
          width: '100%',
          height: '56px',
          '&:hover': {
            backgroundColor: 'white',
          },
          ...VariantSxMap[variant!],
        }}
        {...buttonProps}
      >
        <Box sx={{ flex: 1, flexWrap: 'nowrap', display: 'flex', alignItems: 'center' }}>
          {label}
          {!!activeItemCount && (
            <Box
              sx={{
                fontSize: '0.875rem',
                height: '24px',
                borderRadius: '0.5rem',
                px: 1,
                backgroundColor: 'primary.main',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ml: 1,
              }}
            >
              {activeItemCount}
            </Box>
          )}
        </Box>
      </Button>
      <Popover
        open={isOpen}
        keepMounted
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: -88,
          vertical: 60,
        }}
        slotProps={{
          paper: {
            sx: {
              p: 3,
              borderRadius: 6,
              width: {
                xs: 'calc(100% - 96px)',
                sm: '464px',
              },
              maxHeight: '456px',
            },
          },
        }}
      >
        {content}
      </Popover>
    </>
  );
}
