import { addDays, format, isSunday } from 'date-fns';

export default class DateUtils {
  static AddWorkingDaysToDate(date: Date, workingDays: number): Date {
    let dateToShift = date;
    for (let i = 1; i <= workingDays; i++) {
      dateToShift = addDays(dateToShift, 1);
      if (isSunday(dateToShift)) {
        dateToShift = (addDays(dateToShift, 1));
      }
    }
    return dateToShift;
  }
}

export function convertSqlTimeToDate(time: string): Date {
  // example of sql time format: 09:30:00+00
  const timeSplits = time.split(':');
  const today = new Date();

  today.setHours(parseInt(timeSplits[0]!, 10));
  today.setMinutes(parseInt(timeSplits[1]!, 10));

  return today;
}

export function getFormattedHour(hour: Date): string {
  return format(hour, hour.getMinutes() ? 'H\'h\'mm' : 'H\'h\'');
}
