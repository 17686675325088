import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, Toolbar, Typography, IconButton, Divider } from '@mui/material';
import { ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface FiltersDialogProps {
  open: boolean;
  children: ReactNode;
  onClose: () => void;
}

export default function FiltersDialog({ open, children, onClose }: FiltersDialogProps): React.JSX.Element {
  const { t } = useTranslation(['search']);
  const borderRef = useRef<HTMLHRElement>(null);
  const filterBoxRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!filterBoxRef.current) {
      return;
    }

    const listener = (): void => {
      if (!borderRef.current) {
        return;
      }
      borderRef.current.style.display = filterBoxRef.current?.scrollTop ? 'block' : 'none';
    };

    filterBoxRef.current.addEventListener('scroll', listener);

    return () => filterBoxRef.current?.removeEventListener('scroll', listener);
  }, [filterBoxRef.current]);

  return (
    <Dialog open={open} fullScreen keepMounted>
      <Toolbar sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'background.paper',
        zIndex: 10,
      }}
      >
        <Typography variant="titleLarge" sx={{ flex: 1, textAlign: 'center' }}>
          {t('search:all_filters')}
        </Typography>
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 0, p: 0, mr: 2 }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Toolbar>
      <Divider ref={borderRef} sx={{ display: 'none' }} />
      <Box
        ref={filterBoxRef}
        sx={{
          backgroundColor: 'background.default',
          px: 1,
          height: '100%',
          overflow: 'auto',
        }}
      >
        {children}
      </Box>
      <Box sx={{
        position: 'sticky',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        py: 3,
        backgroundColor: 'background.paper',
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
      >
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
          size="extraLarge"
        >
          {t('search:validate_changes')}
        </Button>
      </Box>
    </Dialog>
  );
}
