import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useMemo, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import FilterButton, { FilterButtonMode, FilterButtonVariant } from './FilterButton';
import useSortedProductFamiliesMap from '../../hooks/useSortedProductFamiliesMap';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';

interface ProductFamiliesFilterProps {
  attribute: string;
  variant?: FilterButtonVariant;
  mode?: FilterButtonMode;
}

export default function ProductFamiliesFilter({ attribute, mode, variant }: ProductFamiliesFilterProps): React.JSX.Element {
  const { t } = useTranslation(['discovery', 'product-family']);
  const { items, refine } = useRefinementList({ limit: 100, attribute });
  const currentRefinements = useNormalizedCurrentRefinements(attribute) as string[];
  const [itemQuery, setItemQuery] = useState('');
  const productFamiliesMap = useSortedProductFamiliesMap(items.map((item) => item.value) as Product_Sub_Family_Name_Enum[]);

  const familyOptions = useMemo((): React.JSX.Element[] => {
    return productFamiliesMap
      .filter((value) => value.label.toLowerCase().indexOf(itemQuery.toLowerCase()) === 0)
      .map(({ value, label }) => (
        <FormControlLabel
          key={value}
          control={<Checkbox checked={currentRefinements.includes(value)} />}
          onChange={() => refine(value)}
          label={label}
          sx={{
            ml: 0,
            '&:not(:last-child)': {
              borderBottom: '1px solid',
              borderColor: 'divider',
            },
          }}
        />
      ));
  }, [items, currentRefinements, itemQuery]);

  return (
    <FilterButton
      buttonProps={{
        startIcon: <ShoppingBasketOutlinedIcon fontSize="small" />,
      }}
      variant={variant}
      onClose={() => setItemQuery('')}
      label={t('discovery:departments')}
      activeItemCount={currentRefinements.length}
      mode={mode}
      name="product_families"
      content={(
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            size="small"
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" sx={{ mr: 1 }} />,
            }}
            onChange={(event) => setItemQuery(event.target.value)}
            value={itemQuery}
            placeholder={t('discovery:search_a_particular_departement')}
            sx={{
              mb: 1.75,
            }}
          />
          <Typography sx={{ mb: 1 }} variant="titleMedium">{t('discovery:select_one_or_several_departments')}</Typography>
          {familyOptions}
        </Box>
      )}
    />
  );
}
