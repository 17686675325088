import { Box, Button, CircularProgress, Divider, FormHelperText, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Controller, useFormContext } from 'react-hook-form';
import EuroIcon from '@mui/icons-material/Euro';
import { useTranslation } from 'react-i18next';
import { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';
import { useProcessInvoice } from './useProcessInvoice';
import { orderAtom } from '../../state/state';
import useCurrencyFormat from '../../../hooks/useCurrencyFormat';
import { InvoiceFormFields } from './types';
import DocumentPreviewer from './DocumentPreviewer';

export default function InvoiceForm(): React.JSX.Element {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation(['order', 'common', 'validation', 'price', 'comments']);
  const currencyFormatter = useCurrencyFormat();
  const {
    reference_id: orderReferenceId,
    total_price_excl_tax,
    total_price_incl_tax,
  } = useAtomValue(orderAtom);
  const { processInvoice, processedInvoice, loading: processInvoiceLoading } = useProcessInvoice({ orderReferenceId });
  const { control, watch, reset, setValue } = useFormContext<InvoiceFormFields>();

  const hasDetectedDisparity = useMemo(() => (
    // watch of react-hook-form returns a string, since the value returned by order atom is a number we need to convert the string to number then compare.
    parseFloat(watch('revisedPriceExclTax') as unknown as string) !== total_price_excl_tax || parseFloat(watch('revisedPriceInclTax') as unknown as string) !== total_price_incl_tax
  ), [watch('revisedPriceExclTax'), watch('revisedPriceInclTax')]);

  const [isOnEditMode, setIsOnEditMode] = useState(hasDetectedDisparity || !watch('reference'));

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files![0];
    processInvoice(file);
  };

  const handleEditClick = (): void => {
    fileInputRef.current!.click();
  };

  useEffect(() => {
    if (!hasDetectedDisparity) {
      setValue('comment', '');
    }
  }, [hasDetectedDisparity]);

  useEffect(() => {
    if (processedInvoice) {
      reset({
        previewUrl: processedInvoice.preview_link,
        reference: processedInvoice.invoice_reference,
        revisedReference: processedInvoice.invoice_reference,
        priceExclTax: processedInvoice.total_price_excl_tax,
        revisedPriceExclTax: processedInvoice.total_price_excl_tax,
        priceInclTax: processedInvoice.total_price_incl_tax,
        revisedPriceInclTax: processedInvoice.total_price_incl_tax,
      }, { keepValues: false });
    }
  }, [processedInvoice]);

  const docPreview = useMemo(() => {
    return (
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => window.open(watch('previewUrl'))}
      >
        <DocumentPreviewer
          language="fr"
          config={{
            pdfZoom: {
              defaultZoom: 1.4,
              zoomJump: 0.5,
            },
          }}
          pluginRenderers={DocViewerRenderers}
          prefetchMethod="GET"
          documents={[{ uri: watch('previewUrl') }]}
        />
      </Box>
    );
  }, [watch('previewUrl')]);

  const formBody = useMemo(() => {
    if (isOnEditMode) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            flex: 1,
          }}
        >
          <Controller
            name="revisedReference"
            control={control}
            rules={{
              required: t('validation:this_field_is_required'),
            }}
            render={({ field, fieldState }) => (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="bodySmall" color="secondary">{t('order:invoice_number')}*</Typography>
                <Box>
                  <OutlinedInput
                    {...field}
                    fullWidth
                    placeholder={t('order:invoice_number')}
                    error={!!fieldState.error || !field.value}
                    sx={{
                      '&.Mui-error fieldset': {
                        borderWidth: 2,
                      },
                    }}
                  />
                  {!field.value && !fieldState.error && !watch('reference') && <FormHelperText error>{t('error:field_not_parsed_automaticaly')}</FormHelperText>}
                  {fieldState.error && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
                </Box>
              </Box>
            )}
          />
          <Controller
            name="revisedPriceExclTax"
            control={control}
            rules={{
              required: t('validation:this_field_is_required'),
              validate: {
                inferiorToPriceIncl: (value, fields) => (parseFloat(value as unknown as string) > parseFloat(fields.revisedPriceInclTax as unknown as string) ? t('error:total_price_tax') : undefined),
              },
            }}
            render={({ field, fieldState }) => {
              const valueDifferentFromOrder = field.value && (parseFloat(field.value as unknown as string) !== total_price_excl_tax);

              return (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography variant="bodySmall" color="secondary">{t('price:price_without_tax_total')}*</Typography>
                  <Box>
                    <OutlinedInput
                      {...field}
                      fullWidth
                      placeholder={t('price:price_without_tax_total')}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': valueDifferentFromOrder
                          ? {
                            borderColor: 'orange',
                            borderWidth: 2,
                          }
                          : null,
                      }}
                      inputProps={{
                        step: '.01',
                        min: '0.01',
                      }}
                      type="number"
                      error={!!fieldState.error || !field.value}
                      endAdornment={(
                        <InputAdornment position="end">
                          <EuroIcon sx={{ width: 20, color: 'common.black' }} />
                        </InputAdornment>
                    )}
                    />
                    {!field.value && !fieldState.error && !watch('priceExclTax') && <FormHelperText error>{t('error:field_not_parsed_automaticaly')}</FormHelperText>}
                    {fieldState.error && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
                    {valueDifferentFromOrder && !fieldState.error && <FormHelperText sx={{ color: 'orange' }}>{t('order:total_of_order_in_kheops', { amount: currencyFormatter.format(total_price_excl_tax!) })}</FormHelperText>}
                  </Box>
                </Box>
              );
            }}
          />
          <Controller
            name="revisedPriceInclTax"
            control={control}
            rules={{
              required: t('validation:this_field_is_required'),
              validate: {
                inferiorToPriceExcl: (value, fields) => (parseFloat(value as unknown as string) < parseFloat(fields.revisedPriceExclTax as unknown as string) ? t('error:total_price_tax') : undefined),
              },
            }}
            render={({ field, fieldState }) => {
              const valueDifferentFromOrder = field.value && (parseFloat(field.value as unknown as string) !== total_price_incl_tax);

              return (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography variant="bodySmall" color="secondary">{t('price:price_tax_included')}*</Typography>
                  <Box>
                    <OutlinedInput
                      {...field}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': valueDifferentFromOrder
                          ? {
                            borderColor: 'orange',
                            borderWidth: 2,
                          }
                          : null,
                      }}
                      placeholder={t('price:price_tax_included')}
                      error={!!fieldState.error || !field.value}
                      endAdornment={(
                        <InputAdornment position="end">
                          <EuroIcon sx={{ width: 20, color: 'common.black' }} />
                        </InputAdornment>
                    )}
                      inputProps={{
                        step: '.01',
                        min: '0.01',
                      }}
                      type="number"
                    />
                    {!field.value && !fieldState.error && !watch('priceInclTax') && <FormHelperText error>{t('error:field_not_parsed_automaticaly')}</FormHelperText>}
                    {fieldState.error && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
                    {valueDifferentFromOrder && !fieldState.error && <FormHelperText sx={{ color: 'orange' }}>{t('order:total_of_order_in_kheops', { amount: currencyFormatter.format(total_price_incl_tax!) })}</FormHelperText>}
                  </Box>
                </Box>
              );
            }}
          />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          border: '1px solid',
          borderColor: 'divider',
          py: 3,
          px: 2,
          borderRadius: 6,
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="bodySmall">{t('order:invoice_number')}</Typography>
            <Typography variant="bodyMedium">{watch('reference')}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="bodySmall">{t('price:price_without_tax_total')}</Typography>
            <Typography color="primary" variant="headlineSmall">{currencyFormatter.format(watch('priceExclTax'))}</Typography>
            <Typography variant="bodySmall" color="secondary">{t('price:price_tax_included')} : {currencyFormatter.format(watch('priceInclTax'))}</Typography>
          </Box>

        </Box>
        <Divider />
        <Button
          startIcon={<ModeEditOutlineOutlinedIcon sx={{ width: 16, height: 16 }} />}
          onClick={() => setIsOnEditMode(true)}
          variant="outlined"
        >
          {t('common:update_data')}
        </Button>
      </Box>
    );
  }, [isOnEditMode, hasDetectedDisparity]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: {
            lg: 'row',
            xs: 'column',
          },
          gap: 3,
        }}
      >
        {formBody}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            alignSelf: 'baseline',
          }}
        >
          <Typography variant="bodySmall">{t('order:your_invoice')}</Typography>
          <Box
            sx={{
              width: 164,
              height: 164,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 4,
              justifyContent: 'center',
              backgroundColor: 'secondary.light',
            }}
          >

            {processInvoiceLoading
              ? (
                <CircularProgress sx={{ mx: 'auto', textAlign: 'center' }} />
              )
              : (
                <>
                  <IconButton
                    onClick={handleEditClick}
                    sx={{
                      position: 'absolute',
                      mb: 15,
                      ml: 15,
                      backgroundColor: 'white',
                    }}
                  >
                    <ModeEditOutlineOutlinedIcon color="secondary" />
                  </IconButton>
                  <Box
                    ref={fileInputRef}
                    component="input"
                    type="file"
                    sx={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                  {docPreview}
                </>

              )}

          </Box>

        </Box>

      </Box>
      {isOnEditMode && (
        <Controller
          name="comment"
          control={control}
          disabled={!hasDetectedDisparity}
          rules={{
            required: hasDetectedDisparity ? t('order:mandatory_comment') : undefined,
          }}
          render={({ field, fieldState }) => (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="bodySmall" color="secondary">{t('comments:comments_one')}*</Typography>
              <Box>
                <OutlinedInput
                  {...field}
                  multiline
                  error={!!fieldState.error}
                  placeholder={t('order:comment_invoice_disparity')}
                  sx={{
                    width: '100%',
                    '&.Mui-error fieldset': {
                      borderWidth: 2,
                    },
                    '&.MuiOutlinedInput-root': {
                      '&.Mui-disabled': {
                        backgroundColor: 'greys.light',
                        '& textarea': {
                          WebkitTextFillColor: '#B8B8B8',
                        },
                        '& fieldset': {
                          borderColor: 'greys.light',
                        },
                      },
                    },
                  }}
                />
                {fieldState.error && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </Box>
            </Box>
          )}
        />
      )}
    </Box>
  );
}
