import { Box, Switch, Typography } from '@mui/material';
import { useCallback, MouseEvent } from 'react';
import { Availability_Status_Enum, Base_Unit } from 'kheops-graphql';
import { FormatBaseUnitQuantity } from 'kheops-utils';
import i18n from '../../i18n';
import { KheopsTypographyVariant } from '../../providers/CustomThemeProvider';
import EditAvailabilityDialog from '../list/EditAvailabilityDialog';
import useOpenable from '../../hooks/useOpenable';
import { ProductFragment } from '../products';

interface BaseUnitStatusProps {
  product: ProductFragment;
  baseUnit: Pick<Base_Unit, 'unit_type' | 'unit_net_content' | 'content_measurement_unit' | 'unit_quantity' | 'billing_type' | 'availability'>;
  baseUnitIndex: number;
  variant: KheopsTypographyVariant;
}

export default function BaseUnitStatus({ baseUnit, baseUnitIndex, product, variant }: BaseUnitStatusProps): React.JSX.Element {
  const { open: openAvailabilityDialog, close: closeAvailabilityDialog, isOpen: isAvailabalityDialogOpen } = useOpenable();
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  const handleAvailabilitySwitchClick = useCallback((event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>): void => {
    event.preventDefault();
    openAvailabilityDialog();
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Switch
          checked={baseUnit.availability === Availability_Status_Enum.Available}
          sx={{ mr: 1 }}
          onClick={(event) => handleAvailabilitySwitchClick(event)}
        />
        <Typography variant={variant}>
          {FormatBaseUnitQuantity(baseUnit, 'fr', numberFormat)}
        </Typography>
      </Box>
      {isAvailabalityDialogOpen
        && <EditAvailabilityDialog baseUnitIndex={baseUnitIndex} product={product} onClose={closeAvailabilityDialog} open={isAvailabalityDialogOpen} />}
    </>
  );
}
