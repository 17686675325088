import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { useAtom, useSetAtom, useAtomValue } from 'jotai';
import { Brand_Enum, Business_Profile_Enum, CustomOrdersInsert, Maybe } from 'kheops-graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import LoadingDots from '../common/components/LoadingDots';
import { useBasketOrders } from '../hooks/useBasketOrders';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import useOpenable from '../hooks/useOpenable';
import { useInsertOrdersActionMutation } from '../mutations/__generated__/createOrdersAction.generated';
import OrderSuggestionsDeliveryDatesDialog from '../orders/tracking-page/OrderSuggestionsDeliveryDatesDialog';
import { BasketDocument } from '../queries/__generated__/basket.generated';
import { RoutePaths } from '../routes/AppRoutes';
import { currentContextAtom, userAtom } from '../state';
import BasketMinimumOrderValues from './BasketMinimumOrderValues';
import { BasketItem, OrderedOrder } from './state/basket';
import { basketDataAtom, currentOrdersCommentsAtom, currentOrdersDeliveryDatesAtom, isBasketLoadingAtom, lastOrdersOrderedAtom, OrdersCommentsMap, OrdersDeliveryDates } from './state/state';

export interface BasketSummaryProps {
  items: BasketItem[];
}

export default function BasketSummary({ items }: BasketSummaryProps): React.JSX.Element {
  const { t } = useTranslation(['basket']);
  const theme = useTheme();
  const currencyFormat = useCurrencyFormat();
  const { id: userId } = useAtomValue(userAtom);
  const { realm } = useAtomValue(currentContextAtom);
  const basket = useAtomValue(basketDataAtom)!;
  const [isLoadingBasket, setIsLoadingBasket] = useAtom(isBasketLoadingAtom);
  const orders = useBasketOrders({ basketItems: items, photoSize: { height: 56, width: 56 } });
  const navigate = useNavigate();
  const setLastOrdersOrdered = useSetAtom(lastOrdersOrderedAtom);
  const [insertOrdersAction] = useInsertOrdersActionMutation({ refetchQueries: [BasketDocument] });
  const [ordersDeliveryDates, updateCurrentOrdersDeliveryDates] = useAtom(currentOrdersDeliveryDatesAtom);
  const [currentOrdersComments, updateCurrentOrdersComments] = useAtom(currentOrdersCommentsAtom);
  const { isOpen, open, close } = useOpenable();

  const suppliersNumber = Object.keys(orders).length;

  const createOrders = async (deliveryDates: OrdersDeliveryDates, ordersComments: OrdersCommentsMap): Promise<void> => {
    const isOrderSuggested = realm === Business_Profile_Enum.Supplier;

    setIsLoadingBasket(true);

    const ordersInsertObject = Object.keys(orders).map<CustomOrdersInsert>((contractId) => {
      const deliveryDate = deliveryDates.get(contractId);
      const comment = ordersComments.get(contractId);

      return {
        contractId,
        deliveryDate: deliveryDate ? deliveryDate.toISOString() : undefined,
        comment,
        items: orders[contractId].items.map(({ packaging, quantity }) => ({
          packagingId: packaging.id,
          quantity,
        })),
      };
    });
    const result = await insertOrdersAction({
      variables: {
        basketId: basket.id,
        userId,
        ordersData: ordersInsertObject,
        isSuggested: isOrderSuggested,
      },
      update: (cache) => {
        cache.evict({ fieldName: 'order' });
      },
    });
    if (result.data?.insertOrdersAction) {
      const latestOrders: OrderedOrder[] = result.data?.insertOrdersAction.map((orderData) => ({
        friendlyId: orderData.friendly_id,
        referenceId: orderData.reference_id,
        totalPrice: orderData.order_price,
        id: orderData.id,
        packagingCount: orderData.packagingCount,
        deliveryDate: orderData.deliveryDate,
        contract: {
          supplying_company: {
            tradeName: orderData.supplying_company_name,
            photos: orderData.supplierPhotoDomain
              ? [
                {
                  photo: { domain: orderData.supplierPhotoDomain, path: orderData.supplierPhotoPath! },
                }]
              : [],
          },
          buying_company: {
            tradeName: orderData.buying_company_name,
            photos: orderData.buyerPhotoDomain
              ? [
                {
                  photo: { domain: orderData.buyerPhotoDomain, path: orderData.buyerPhotoPath! },
                }]
              : [],
            brand: orderData.buying_company_brand as Maybe<Brand_Enum>,
          },
        },
        isOrderSuggested,
      }));
      setLastOrdersOrdered(latestOrders);
      updateCurrentOrdersDeliveryDates();
      updateCurrentOrdersComments();
      navigate(RoutePaths.ORDERS_CONFIRMATION);
    }
  };

  const submitOrders = useCallback(async (): Promise<void> => {
    if (realm === Business_Profile_Enum.Supplier && ordersDeliveryDates.size !== Object.keys(orders).length) {
      open();
    } else {
      await createOrders(ordersDeliveryDates, currentOrdersComments);
    }
  }, [orders, ordersDeliveryDates, currentOrdersComments]);

  const handleDeliveryDatesDialogClose = async (deliveryDates?: OrdersDeliveryDates): Promise<void> => {
    if (deliveryDates) {
      await createOrders(deliveryDates, currentOrdersComments);
    }

    close();
  };

  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        }}
      >
        <Typography variant="titleMedium">
          {t(
            `basket:${realm === Business_Profile_Enum.Buyer ? 'finalize_your_order' : 'finalize_your_suggestion'}`,
            { count: suppliersNumber },
          )}
        </Typography>
        <Typography variant="bodySmall" sx={{ mt: 0.5, mb: 2 }}>
          {t(
            `basket:${realm === Business_Profile_Enum.Buyer ? 'finalize_your_order_description' : 'finalize_your_suggestion_description'}`,
            { count: suppliersNumber },
          )}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="bodySmall">
          {t('basket:total_excluding_tax')}
        </Typography>
        <LoadingDots loading={isLoadingBasket}>
          <Typography variant="labelLarge" color="primary">
            {currencyFormat.format(basket.total_price_excl_tax!)}
          </Typography>
        </LoadingDots>
      </Box>
      <Box
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        }}
      >
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
          <Typography variant="bodySmall">
            {t('basket:VAT')}
          </Typography>
          <LoadingDots loading={isLoadingBasket}>
            <Typography variant="labelMedium">
              {currencyFormat.format(basket.total_tax!)}
            </Typography>
          </LoadingDots>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
          <Typography variant="bodySmall">
            {t('basket:total_including_tax')}
          </Typography>
          <LoadingDots loading={isLoadingBasket}>
            <Typography variant="labelMedium">
              {currencyFormat.format(basket.total_price_incl_tax!)}
            </Typography>
          </LoadingDots>
        </Box>
      </Box>
      <BasketMinimumOrderValues orders={orders} />
      <Button
        variant="contained"
        color="primary"
        sx={{
          textAlign: 'center',
          width: '100%',
          mt: 1,
        }}
        disabled={isLoadingBasket || !suppliersNumber}
        onClick={submitOrders}
      >
        {t(`basket:${realm === Business_Profile_Enum.Buyer ? 'order' : 'suggest'}`)}
      </Button>
      {realm === Business_Profile_Enum.Supplier && (
        <OrderSuggestionsDeliveryDatesDialog
          orders={orders}
          deliveryDates={ordersDeliveryDates}
          open={isOpen}
          onClose={(deliveryDates) => handleDeliveryDatesDialogClose(deliveryDates)}
        />
      )}
    </>
  );
}
