import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SettingsDialogViewProps } from './SettingsDialog';
import SuccessCarrotImage from '../../assets/images/success_carrot.png';

export default function SettingsSaved({ next }: SettingsDialogViewProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'settings']);

  return (
    <>
      <DialogTitle sx={{ pt: 0 }}>
        <Typography variant="displaySmall" sx={{ mb: 2 }}>
          {t('settings:your_profile_has_been_updated')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box
          component="img"
          src={SuccessCarrotImage}
          sx={{
            mt: 1,
            width: {
              xs: 156,
              sm: 232,
            },
            height: {
              xs: 156,
              sm: 232,
            },
          }}
        />
        <Typography variant="bodyMedium" sx={{ mb: 1, textAlign: 'center', mt: 3, color: 'text.primary' }}>
          {t('settings:your_profile_has_been_updated_description')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => next()}
          color="primary"
          variant="outlined"
          sx={{
            px: 2,
            mr: 1,
          }}
        >
          {t('common:close')}
        </Button>
        <Button
          onClick={() => next(true)}
          variant="contained"
          color="primary"
        >
          {t('settings:see_my_profile')}
        </Button>
      </DialogActions>
    </>

  );
}
